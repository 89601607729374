import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Segment, Form, Button, Grid } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import { CustomDateInput } from '../../custom/date/CustomDateInput';
import { profileFormSchema } from './validations';

type Props = {
	onSubmit: Function;
	updateLoggedUser: Function;
	initialValues?: any;
};

export interface ProfileFormValues {
	username: string;
	ci: string;
	rut: string;
	name: string;
	surname: string;
	email: string;
	dob: string;
	active: boolean;
	imageURL: string;
}

export class ProfileForm extends Component<Props, any> {
	dateInput = React.createRef<any>();

	INITIAL_VALUES = {
		username: '',
		ci: '',
		rut: '',
		name: '',
		surname: '',
		email: '',
		dob: '2002/01/01',
		active: true,
	} as ProfileFormValues;

	render() {
		const profile = this.props.initialValues
			? this.props.initialValues.data
			: this.INITIAL_VALUES;
		// profile.dob = new Date(profile.dob);
		return (
			<Segment textAlign="left">
				<Formik
					initialValues={profile}
					onSubmit={async (values, actions) => {
						await this.props.onSubmit(values);
						await this.props.updateLoggedUser(values);
					}}
					validationSchema={profileFormSchema}
				>
					{({
						values,
						errors,
						touched,
						handleSubmit,
						resetForm,
						isSubmitting,
						setFieldValue,
						getFieldProps,
					}) => (
						<Form
							size="large"
							className="ui error"
							onSubmit={handleSubmit}
							loading={isSubmitting}
						>
							<Grid columns={2} divided>
								<Grid.Row>
									<Grid.Column size={8}>
										<Field
											label="Nick Name:"
											name="username"
											fluid
											icon="user"
											iconPosition="left"
											placeholder="User name"
											as={Form.Input}
											error={
												errors.username && touched.username
                                                ? {
                                                    content:errors.username,
                                                    pointing: 'below',
                                                    }: null}
										/>
										<Field
											label="Name:"
											name="name"
											fluid
											icon="user"
											iconPosition="left"
											placeholder="Name"
											as={Form.Input}
											error={errors.name && touched.name
                                                ? {
                                                    content:errors.name,
                                                    pointing: 'below',
                                                    }: null}
										/>
										<Field
                                            label='Surname:'
											name="surname"
											fluid
											icon="user"
											iconPosition="left"
											placeholder="Surname"
											as={Form.Input}
											error={
												errors.surname && touched.surname
                                                ? {
                                                    content:errors.surname,
                                                    pointing: 'below',
                                                    }: null}
										/>
                                        <DatePicker
											name="dob"
											selected={new Date(values.dob)}
											dateFormat="dd/MM/yyyy"
											showYearDropdown
											ref={(input: any) =>
												(this.dateInput = input)
											}
											customInput={
												<CustomDateInput
                                                    label='Dob:'
													error={errors.dob}
													touched={touched.dob}
													value={values.dob}
													onClick={() =>
														this.dateInput.current?.click()
													}
												/>
											}
											onChange={(date) =>
												setFieldValue('dob', date)
											}
										></DatePicker>                                        
									</Grid.Column>
									<Grid.Column size={8}>
										<Field
                                            label='Email:'
											name="email"
											fluid
											icon="envelope"
											iconPosition="left"
											placeholder="E-mail address"
											as={Form.Input}
											error={errors.email && touched.email
                                                ? {
                                                    content: errors.email,
                                                    pointing: 'below',
                                                    }: null}
										/>
										<Field
                                            label='Identity Card:'
											name="ci"
											fluid
											icon="address card"
											iconPosition="left"
											placeholder="Identity Card"
											as={Form.Input}
											error={errors.ci && touched.ci
                                                ? {
                                                    content: errors.ci,
                                                    pointing: 'below',
                                                    }: null}
										/>
										<Field
                                            label='RUT:'
											name="rut"
											fluid
											icon="id badge"
											iconPosition="left"
											placeholder="Worker Number"
											as={Form.Input}
											error={errors.rut && touched.rut
                                                ? {
                                                        content: errors.rut,
                                                        pointing: 'below',
                                                    }: null}
                                    />
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<br />
							<br />
							<Button.Group style={{ float: 'right' }}>
								<Button
									color="grey"
									type="reset"
									onClick={() => resetForm()}
								>
									Reset
								</Button>
								<Button.Or />
								<Button type="submit" positive>
									Save
								</Button>
							</Button.Group>
						</Form>
					)}
				</Formik>
			</Segment>
		);
	}
}
