import React, { Component } from 'react';
import { Grid, Header, Menu } from 'semantic-ui-react';
import { LoginForm } from './LoginForm';
import { RegisterForm } from './RegisterForm';
import { apiSignIn, apiRegisterUser } from '../../actions/auth/auth-actions';
import { connect } from 'react-redux';

type Props = {
    apiSignIn: Function;
    apiRegisterUser: Function;
};
type State = {};

class LoginRegisterForm extends Component<Props, State> {

    state = { activeItem: 'login' };

    handleItemClick = (e: any, {name}: any) => {
        this.setState({ activeItem:  name });
    }

    render() {
        const { activeItem } = this.state;
        const form = activeItem === 'login' ? <LoginForm onSubmit={ this.props.apiSignIn }/> : <RegisterForm onRegister={this.props.apiRegisterUser}/>;

        return (
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='top'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <br/>
                    <Header as='h2' color='green' textAlign='center' style={{ fontSize: 50 }}>
                        {'{ chelabs }'}
                    </Header>
                    <Menu tabular>
                        <Menu.Item name='login' active={activeItem === 'login'} onClick={this.handleItemClick} />
                        {/* <Menu.Item name='register' active={activeItem === 'register'} onClick={this.handleItemClick} /> */}
                    </Menu>
                    {form}
                </Grid.Column>
            </Grid>
        );
    }
}

export default connect(null, { apiSignIn, apiRegisterUser })(LoginRegisterForm);