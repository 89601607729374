import React, { SyntheticEvent, useState } from 'react';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

export const ExpenseFilter = (props: any) => {
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [searchText, setSearchText] = useState('');
	const [selectedCategory, setSelectedCategory] = useState(undefined);
	return (
		<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
			<div>
				<Dropdown
					style={{ marginRight: '10px' }}
					placeholder="Select Category"
					selection
					options={props.categoriesOptions}
					onChange={(e: SyntheticEvent, data: any) => {
						setSelectedCategory(data.value);
						props.handleSelectCategory(e, data);
					}}
					value={selectedCategory}
				/>
				<Input
					style={{ marginRight: '10px' }}
					onChange={(e: SyntheticEvent, data: any) => {
						setSearchText(data.value);
						props.handleSearch(e, data);
					}}
					icon="search"
					placeholder="Search..."
					value={searchText}
				/>
			</div>
			<div style={{ display: 'flex' }}>
				<DatePicker
					name="startDate"
					selected={startDate}
					dateFormat="dd/MM/yyyy"
					showYearDropdown
					onChange={(date: any) => {
						setStartDate(date);
						props.handleStartDateChange(date);
					}}
					placeholderText="From Date"
					customInput={
						<Input
							style={{ marginRight: '10px' }}
							icon="calendar"
							iconPosition="left"
						/>
					}
				/>
				<DatePicker
					name="endDate"
					selected={endDate}
					dateFormat="dd/MM/yyyy"
					showYearDropdown
					onChange={(date: any) => {
						setEndDate(date);
						props.handleEndDateChange(date);
					}}
					placeholderText="To Date"
					customInput={
						<Input
							style={{ marginRight: '10px' }}
							icon="calendar"
							iconPosition="left"
						/>
					}
				/>
				<Button
					circular
					icon="refresh"
					onClick={() => {
						setSearchText('');
						setSelectedCategory(0 as any);
						setStartDate(undefined);
						setEndDate(undefined);
						props.handleRefresh();
					}}
				/>
			</div>
		</div>
	);
};
