import React, { Component } from 'react';
import { Card, Button, Image, Segment, Header, Message, Label, Icon } from 'semantic-ui-react';
import ClientModal from './ClientModal';
import { connect } from 'react-redux';
import {
    getResource,
    getAllResources,
    deleteResource,
    openModal,
    setResourceId
} from '../../../actions/crud/crud.actions';
import { RESOURCES } from '../../../actions/crud/resources';
import _ from 'lodash';
import ConfirmModal from '../../common/modal/ConfirmModal';
import { ClientFormValues } from './helper';

class ClientPage extends Component<any, any> {

    defaultImageURL = 'https://i.pravatar.cc/150?img=';

    async componentDidMount() {
        await this.props.getAllResources(RESOURCES.clients);
    }

    handleCreate = () => {
        // this.props.openModal(RESOURCES.clients, !this.props.isOpenModal);
    }

    handleEdit = async (id: string | undefined) => {
        await this.props.getResource(RESOURCES.clients, id);
        this.props.openModal(RESOURCES.clients, !this.props.isOpenModal, id);
    }

    handleDelete = async (id: string) => {
        this.props.deleteResource(RESOURCES.clients, id);
    }

    render() {
        const items = this.props.clients?.values;
        let render;
        let cardItems;
        const style = { backgroundColor: '#f5f5f5' };
        const labelStyle = { marginBottom: '8px' }
        if (!_.isEmpty(items)) {
            cardItems = items.map((item: ClientFormValues, index: number) => {
                return (
                    <Card key={item._id} color='green'>
                        <Card.Content>
                            <Image
                                circular
                                floated='right'
                                size='mini'
                                src={item.imageURL || this.defaultImageURL + index}
                            />
                            <Card.Header>{item.name} {item.surname}</Card.Header>
                            <Card.Meta>{item.email}</Card.Meta>
                            <Card.Description>
                                { item.phone ? <Label style={labelStyle} size='medium'><Icon name='phone' /> {item.phone}</Label>: null }
                                { item.celPhone ? <Label style={labelStyle} size='medium'><Icon name='mobile alternate' /> {item.celPhone}</Label> : null }
                                <Label style={labelStyle} size='medium'>
                                    <Icon name='world' /> {item.external ? 'External' : 'Internal'}
                                </Label>
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <div className='ui two buttons'>
                                <Button inverted color='green' onClick={() => this.handleEdit(item._id) }>Edit</Button>
                                <ConfirmModal
                                    id={item._id}
                                    title={'Delete Client'}
                                    message={`Are you sure that you want to delete the client ${item.name} ${item.surname}?`}
                                    triggerTitle={'Delete'}
                                    titleIcon='trash alternate'
                                    isDelete={true}
                                    onConfirm={this.handleDelete}
                                />
                            </div>
                        </Card.Content>
                    </Card>
                );
            });
            render = (
                <Segment color='green' style={style}>
                    <Card.Group>
                        {cardItems}
                    </Card.Group>
                </Segment>
            );
        } else {
            return (
                <div>
                    <Segment clearing color='green' style={style}>
                        <Header as='h2' floated='left'>Clients</Header>
                        <ClientModal />
                    </Segment>
                    <Message info>
                        <Message.Header>There are no clients to show!</Message.Header>
                        <p>You can add clients with the button located on the top right</p>
                    </Message>
                </div>
            );
        }

        return (
            <div>
                <Segment clearing color='green' style={style}>
                    <Header as='h2' floated='left'>Clients</Header>
                    <ClientModal />
                </Segment>
                {render}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        clients: state.entities.client.clients,
        isOpenModal: state.entities.client.isOpenModal
    };
};

export default connect(mapStateToProps, {
    getAllResources,
    getResource,
    deleteResource,
    openModal,
    setResourceId
})(ClientPage);
