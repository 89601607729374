import * as Yup from 'yup';

export const clientFormSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Name Too Short!')
        .max(50, 'Name Too Long!')
        .required('Required!'),
    surname: Yup.string()
        .min(3, 'Surname Too Short!')
        .max(50, 'Surname Too Long!')
        .required('Required!'),
    email: Yup.string()
        .min(1, 'Email Too Short!')
        .max(35, 'Email Too Long!')
        .email('Invalid Email!')
        .required('Required!'),
    phone: Yup.string()
        .min(8, 'Phone Too Short!')
        .max(10, 'Phone Too Long!')
        .notRequired(),
    celPhone: Yup.string()
        .min(9, 'Cel Phone Too Short!')
        .max(10, 'Cel Phone Too Long!')
        .notRequired(),
    active: Yup.bool(),
    external: Yup.bool()
        .notRequired(),
    address: Yup.string()
        .min(5, 'Address Too Short!')
        .max(80, 'Address Too Long!')
        .notRequired(),
    rut: Yup.string()
        .min(8, 'RUT Too Short!')
        .max(16, 'RUT Too Long!')
        .notRequired(),
});