import React, { Component } from "react";
import { Menu, Dropdown, Icon, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { toggleSideMenu } from "../../../actions/menu/menu-actions";
import { apiSignOut } from "../../../actions/auth/auth-actions";
import { setMenuActiveItem } from '../../../actions/menu/menu-actions';
import history from '../../../History';

type Props = {
    isVisibleSideMenu?: boolean;
    toggleSideMenu?: any;
    apiSignOut?: any;
    setMenuActiveItem: Function;
    loggedUser?: any;
};

type State = {
    isVisibleSideMenu?: boolean;
    activeItem: string;
    loggedUser: any;
};

class HeaderMenu extends Component<Props, State> {

    handleLogout = () => {
        this.props.apiSignOut();
        this.props.setMenuActiveItem('dashboard');
    };

    handleGoToProfile = () => {
        history.push('/profile');
    }

    handleToggleMenu = () => {
        this.props.toggleSideMenu(!this.props.isVisibleSideMenu);
    };

    render() {
        const loggedUser = this.props.loggedUser;
        const company = "{ chelabs }";
        const userMenuOptions = [
            { 
                key: "user",
                text: "Profile",
                icon: "user",
                onClick: () => this.handleGoToProfile()
            },
            { key: "settings", text: "Settings", icon: "settings" },
            {
                key: "sign-out",
                text: "Sign Out",
                icon: "sign out",
                onClick: () => this.handleLogout(),
            },
        ];
        const userMenuItem = (
            <div>
                <Image
                    src="https://react.semantic-ui.com/images/wireframe/square-image.png"
                    avatar
                />
                &nbsp;
                <span>{loggedUser.username}</span>
            </div>
        );

        return (
            <Menu inverted>
                <Menu.Item header>{company}</Menu.Item>
                <Menu.Item name="toggle" onClick={this.handleToggleMenu}>
                    <Icon name="bars" />
                </Menu.Item>
                <Menu.Menu position="right">
                    <Dropdown
                        options={userMenuOptions}
                        trigger={userMenuItem}
                        pointing
                        className="link item"
                    ></Dropdown>
                </Menu.Menu>
            </Menu>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedUser: state.auth.loggedUser,
        isVisibleSideMenu: state.menu.isVisibleSideMenu
    };
};
export default connect(mapStateToProps, { apiSignOut, toggleSideMenu, setMenuActiveItem })(HeaderMenu);
