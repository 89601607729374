import React from "react";
import { connect } from "react-redux";
import "./App.css";
import LoginRegisterForm from "./components/auth/LoginRegisterForm";
import SideBarMenu from "./components/common/menu/SideBarMenu";
import HeaderMenu from "./components/common/menu/HeaderMenu";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type State = {};

type Props = {
    isSignedIn: any;
};

class App extends React.Component<Props, State> {
    render(): any {
        if (this.props.isSignedIn) {
            return (
                <div>
                    <HeaderMenu />
                    <SideBarMenu />
                    <ToastContainer />
                </div>
            );
        } else {
            return (
                <div>
                    <ToastContainer />
                    <LoginRegisterForm />
                </div>
            );
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
};

export default connect(mapStateToProps)(App);
