

export const DEFAULT_LIMIT = 6;

export interface QueryParams {
    filters?: any;
    limit?: number;
    skip?: number;
    sort?: any;
    page?: number;
}

export const getQueryParams = (query: QueryParams | undefined) => {
    if (!query) return undefined; 
    return {
        params: {
            filters: query.filters,
            limit: query.limit || DEFAULT_LIMIT,
            skip: getSkip(query.page, query.limit || DEFAULT_LIMIT),
            sort: query.sort
        }
    }
}

const getSkip = (page?: number, limit?: number): number => {
    if (page && limit) {
        return (page * limit) - limit;
    }
    return 0;
}