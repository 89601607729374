import React from 'react';
import { Table, Icon, Button, Form } from 'semantic-ui-react';
import { FieldArrayRenderProps, Field } from 'formik';
import { ROWS_INITIAL_VALUES } from './helper';
import _ from 'lodash';

const calculateAmount = (form: any, index?: number) => {
    const rows = _.filter(form.values.rows, (r, i) => index !== +i)
    const totalAmount = _.reduce(rows, (sum, v) => sum + v.amount, 0);
    form.setFieldValue('total', totalAmount);
    form.setFieldValue('subTotal', totalAmount);    
}

const handleRateOrQuantityChange = (form: any, index: number, value: any, field: string) => {
    const val = parseFloat(value.target.value);
    if (val) {
        form.setFieldValue(`rows.${index}.${field}`, val);
        const amount = form.values.rows[index][field === 'rate' ? 'quantity' : 'rate'] * val;
        form.values.rows[index].amount = amount;
        form.setFieldValue(`rows.${index}.amount`, amount);
        calculateAmount(form);
    }
}

const handleDeleteItem = (props: any, rows: any, index: number) => {
    props.remove(index);
    calculateAmount(props.form, index);
}

export const InvoiceItemForm = (props: FieldArrayRenderProps | any) => {
    const rows = props.form.values.rows;
    const errors = props.form.errors;
    const touched = props.form.touched;
    return (
    <>
    <Table compact celled definition>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Item</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Rate</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell />
            </Table.Row>
        </Table.Header>
        <Table.Body>
        { rows && rows.length > 0 ? (rows.map((row: any, index: number) => (
            <Table.Row key={index}>
                <Table.Cell collapsing>
                    # {index + 1}
                </Table.Cell>
                <Table.Cell width={8}>
                    <Field
                        as={Form.Input}
                        fluid
                        name={`rows.${index}.notes`}
                        error={ errors.rows && errors.rows[index]?.notes && touched.rows && touched.rows[index]?.notes ? {
                            content: errors.rows[index]?.notes
                        } : null }
                    />
                </Table.Cell>
                <Table.Cell width={2}>
                    <Field
                        type='number'
                        as={Form.Input}
                        fluid
                        name={`rows.${index}.quantity`}
                        onChange={(value: any) => handleRateOrQuantityChange(props.form, index, value, 'quantity')}
                        error={ errors.rows && errors.rows[index]?.quantity && touched.rows && touched.rows[index]?.quantity ? {
                            content: errors.rows[index]?.quantity
                        } : null }
                    />
                </Table.Cell>
                <Table.Cell width={3}>
                    <Field
                        type='number'
                        as={Form.Input}
                        fluid
                        name={`rows.${index}.rate`}
                        onChange={(value: any) => handleRateOrQuantityChange(props.form, index, value, 'rate')} 
                        error={ errors.rows && errors.rows[index]?.rate && touched.rows && touched.rows[index]?.rate ? {
                            content: errors.rows[index]?.rate
                        } : null }
                    />
                </Table.Cell>
                <Table.Cell width={3}>$ { rows[index].amount }</Table.Cell>
                <Table.Cell>
                    <Icon
                        style={{ cursor: 'pointer' }}
                        name='trash'
                        color='red'
                        disabled={rows.length === 1}
                        onClick={() => handleDeleteItem(props, rows, index)}
                    />
                </Table.Cell>
            </Table.Row>
            ))
        ) : null }
        </Table.Body>
        <Table.Footer fullWidth>
            <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan='5'>
                    <Button
                        type='button'
                        floated='right'
                        icon
                        labelPosition='left'
                        primary
                        size='small'
                        onClick={() => props.insert(rows.length, ROWS_INITIAL_VALUES)}
                    ><Icon name='plus' /> Add Item
                    </Button>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>        
    </Table>
    </>        
    );
}