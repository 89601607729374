import { format } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Item } from 'semantic-ui-react';
import { getLastInfo } from '../../../actions/dashboard/dashboard.actions';
import { isPDFURL } from '../../../utils/FileUtils';
import { PDFViewer } from '../../custom/image/PDFViewer';

class DashboardLastInfo extends React.Component<any, any> {
	image = 'https://via.placeholder.com/300';

	async componentDidMount() {
		await this.props.getLastInfo();
	}

	render() {
		const expenses = this.props.lastInfo?.expenses;
		const invoices = this.props.lastInfo?.invoices;
		return (
			<Grid>
				<Grid.Row>
					<Grid.Column width={8}>
						<Item.Group>
							{invoices?.map((invoice: any) => {
								return (
									<Item key={invoice._id}>
										{!isPDFURL(invoice.imageURL) ? (
											<Item.Image
												size="tiny"
												src={
													invoice.imageURL ||
													this.image
												}
											/>
										) : (
											<div style={{ margin: '0 20px 0 0' }}>
												<PDFViewer
													width={80}
													height={60}
													url={invoice.imageURL}
												/>
											</div>
										)}
										<Item.Content>
											<Item.Header>
												{invoice?.client?.fullName}
											</Item.Header>
											<Item.Meta>
												<span className="price">
													$ {invoice.total}
												</span>
												<span className="stay">
													{format(
														new Date(invoice.date),
														'dd/MM/yyyy'
													)}
												</span>
											</Item.Meta>
											<Item.Description>
												{invoice.notes}
											</Item.Description>
										</Item.Content>
									</Item>
								);
							})}
						</Item.Group>
					</Grid.Column>
					<Grid.Column width={8}>
						<Item.Group>
							{expenses?.map((expense: any) => {
								return (
									<Item key={expense._id}>
										<div style={{ margin: '0 20px 0 0' }}>
											{isPDFURL(expense.imageURL) ? (
												<PDFViewer
													width={80}
													height={60}
													url={expense.imageURL}
												/>
											) : (
												<Item.Image
													size="tiny"
													src={this.image}
												/>
											)}
										</div>
										<Item.Content>
											<Item.Header>
												{expense.category.name}
											</Item.Header>
											<Item.Meta>
												<span className="price">
													$ {expense.total}
												</span>
												<span className="stay">
													{format(
														new Date(expense.date),
														'dd/MM/yyyy'
													)}
												</span>
											</Item.Meta>
											<Item.Description>
												{expense.notes}
											</Item.Description>
										</Item.Content>
									</Item>
								);
							})}
						</Item.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		lastInfo: state.entities.dashboard.lastInfo,
	};
};

export default connect(mapStateToProps, {
	getLastInfo,
})(DashboardLastInfo);
