import { MENU_SET_ACTIVE_ITEM , TOGGLE_SIDE_MENU} from './menu-types';

export const setMenuActiveItem = (activeItem: string) => {
    return {
        type: MENU_SET_ACTIVE_ITEM,
        payload: activeItem
    };
};

export const toggleSideMenu = (hide: boolean) => {
    return {
        type: TOGGLE_SIDE_MENU,
        payload: hide
    };
};