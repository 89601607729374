import { getQueryParams, QueryParams } from "../crud/crud.util";
import api from '../../api/invoice.api';
import { GET_EXPENSES_BY_MONTH, RESET_EXPENSES_BY_MONTH } from "./expense.types";
import { AxiosRequestConfig } from "axios";

export const getExpenseByMonth = (resource: string, query?: QueryParams) => async (dispatch: Function) => {
    const response = await api.get(resource, getQueryParams(query) as AxiosRequestConfig);
    dispatch({
        type: GET_EXPENSES_BY_MONTH,
        payload: response?.data.data
    });
}

export const resetExpenseByMonth = () => {
    return {
        type: RESET_EXPENSES_BY_MONTH,
        payload: []
    }
}