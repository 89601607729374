import React, { Component } from 'react';
import { Button, Form, Segment } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import { registerFormSchema } from './validation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomDateInput } from '../custom/date/CustomDateInput';

type Props = {
    onRegister: Function;
};
type State = {};

export interface RegisterFormValues {
    username: string;
    ci: string;
    password: string;
    confirmPassword: string;
    name: string;
    surname: string;
    email: string;
    dob: Date;
    active: boolean;
}

export class RegisterForm extends Component<Props, State> {

    INITIAL_VALUES = {
        username: '',
        ci: '',
        password: '',
        confirmPassword: '',
        name: '',
        surname: '',
        email: '',
        dob: new Date('2002/01/01'),
        active: true
    } as RegisterFormValues;

    render() {
        return (
            <Segment textAlign='left'>
                <Formik
                    initialValues={this.INITIAL_VALUES}
                    onSubmit={(values, actions) => {
                        actions.resetForm();
                        this.props.onRegister(values);
                    }}
                    validationSchema={registerFormSchema}
                >
                    {({values, errors, touched, handleSubmit, resetForm, isSubmitting, setFieldValue, getFieldProps}) => (
                        <Form size='large' className='ui error' onSubmit={handleSubmit} loading={isSubmitting}>
                            <Field
                                name='username'
                                fluid
                                icon='user'
                                iconPosition='left'
                                placeholder='User name'
                                as={Form.Input}
                                error={errors.username && touched.username ? {
                                    content: errors.username,
                                    pointing: 'below'
                                }: null} />
                            <Field
                                name='name'
                                fluid
                                icon='user'
                                iconPosition='left'
                                placeholder='Name'
                                as={Form.Input}
                                error={errors.name && touched.name ? {
                                    content: errors.name,
                                    pointing: 'below'
                                }: null} />
                            <Field
                                name='surname'
                                fluid
                                icon='user'
                                iconPosition='left'
                                placeholder='Surname'
                                as={Form.Input}
                                error={errors.surname && touched.surname ? {
                                    content: errors.surname,
                                    pointing: 'below'
                                }: null} />
                            <Field
                                name='email'
                                fluid
                                icon='envelope'
                                iconPosition='left'
                                placeholder='E-mail address'
                                as={Form.Input}
                                error={errors.email && touched.email ? {
                                    content: errors.email,
                                    pointing: 'below'
                                }: null} />
                            <Field
                                name='password'
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                as={Form.Input}
                                error={errors.password && touched.password ? {
                                    content: errors.password,
                                    pointing: 'below'
                                }: null} />
                            <Field
                                name='confirmPassword'
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Confirm Password'
                                type='password'
                                as={Form.Input}
                                error={errors.confirmPassword && touched.confirmPassword ? {
                                    content: errors.confirmPassword,
                                    pointing: 'below'
                                }: null} />

                            <DatePicker
                                className='field'
                                name='dob'
                                selected={values.dob}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                customInput={
                                    <CustomDateInput 
                                        error={errors.dob}
                                        touched={touched.dob}
                                        value={values.dob} 
                                        onClick={getFieldProps('dob').onChange}/>
                                }
                                onChange={date => setFieldValue('dob', date)}
                            >
                            </DatePicker>
                            <br/>                   
                            <br/>                   

                            <Button 
                                color='green' 
                                type='submit'
                                fluid size='large'>Register</Button>
                            <br/>
                            <Button 
                                color='grey'
                                type='reset'
                                onClick={() => resetForm()}
                                fluid size='large'>Reset</Button>
                        </Form>
                    )}
                </Formik>
            </Segment>
        );
    }
}