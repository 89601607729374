import { endOfMonth, getYear } from 'date-fns';

import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Grid } from 'semantic-ui-react';
import { getExpenseByMonth, resetExpenseByMonth } from '../../../../actions/expense/expense.actions';
import { RESOURCES } from '../../../../actions/crud/resources';
import { months, years } from './helper';
import { ExpenseCalculatorTable } from './ExpenseCalculatorTable';

const INITIAL_FILTER = {
    month: 0,
    year: 0
}

class ExpenseCalculator extends Component<any, any> {

    filters = INITIAL_FILTER;

    componentDidMount() {
        this.props.resetExpenseByMonth();
    }

    getExpenses = async (fechaInicio: Date, fechaFin: Date) => {
        await this.props?.getExpenseByMonth(RESOURCES.expenses, {
            filters: {
                date: {
                    $gte: fechaInicio,
                    $lte: fechaFin
                }
            },
            sort: { date: -1 },
            limit: 1000
        })
    }

    handleYearChange = async (e: SyntheticEvent, data: any) => {
        if (data.value) {
            const month = this.filters.month;
            const year = data.value;
            this.filters.year = year;
            if (month) {
                const fechaInicio = new Date(+year, +month - 1, 1);
                const fechaFin = endOfMonth(fechaInicio);
                await this.getExpenses(fechaInicio, fechaFin);
            }
        }
    }

    handleMonthChange = async (e: SyntheticEvent, data: any) => {
        if (data.value) {
            const month = data.value;
            this.filters.month = month;
            const year = this.filters.year ? this.filters.year : getYear(new Date());
            const fechaInicio = new Date(+year, +month - 1, 1);
            const fechaFin = endOfMonth(fechaInicio);
            await this.getExpenses(fechaInicio, fechaFin);
        }
    }

    render(): React.ReactNode {
        return (
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Dropdown
                            onChange={this.handleMonthChange}
                            placeholder='Select a month'
                            fluid
                            selection
                            options={months}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Dropdown
                            onChange={this.handleYearChange}
                            placeholder='Select a year'
                            fluid
                            selection
                            options={years}
                            defaultValue={String(getYear(new Date()))}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <ExpenseCalculatorTable
                            expenses={this.props.expensesByMonth}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

}

const mapStateToProps = (state: any) => {
    return {
		expensesByMonth: state.entities.expense?.expensesByMonth,
		loggedUser: state.auth.loggedUser
    };
};

export default connect(mapStateToProps, {
    getExpenseByMonth,
    resetExpenseByMonth
})(ExpenseCalculator);