import React from 'react';
import { Button, Icon, Item, Label } from 'semantic-ui-react';
import { format } from 'date-fns';
import { TotalAmount } from '../../common/money/TotalAmount';
import { isImageURL, isPDFURL } from '../../../utils/FileUtils';
import { PDFViewer } from '../../custom/image/PDFViewer';
import ConfirmModal from '../../common/modal/ConfirmModal';

type ItemProps = {
	children?: any;
	invoice: any;
	handleClick: (e: any, titleProps: any) => void;
	handleEdit: (id: string) => Promise<void>;
	handleDelete: (id: string) => Promise<void>;
};

export const InvoiceItem = (props: ItemProps) => {
	const { invoice } = props;
	const defaultImage = 'https://via.placeholder.com/300';
	return (
		<Item>
			{isImageURL(invoice.imageURL) ? (
				<Item.Image size="small" src={invoice.imageURL} />
			) :
				isPDFURL(invoice.imageURL) ? (
					<div style={{ margin: '0 20px 0 0' }}>
						<PDFViewer width={150} url={invoice.imageURL} />
					</div>
				) : <Item.Image size="small" src={defaultImage} />}
			<Item.Content verticalAlign="middle">
				<Item.Header style={{ display: 'flex' }}>
					<h2>{invoice.client?.fullName}</h2>
					<Label.Group style={{ marginLeft: 'auto', order: 2 }}>
						<TotalAmount
							currency={invoice.currency}
							total={invoice.total}
							size="big"
						/>
					</Label.Group>
				</Item.Header>
				<Item.Meta>
					<Label.Group>
						<Label>
							<Icon name="calendar alternate outline" />
							{format(new Date(invoice.date), 'dd/MM/yyyy')}
							<Label.Detail>Date</Label.Detail>
						</Label>
						<Label>
							<Icon name="calendar alternate outline" />
							{format(new Date(invoice.dueDate), 'dd/MM/yyyy')}
							<Label.Detail>Due Date</Label.Detail>
						</Label>
					</Label.Group>
				</Item.Meta>
				<Item.Description>{invoice.notes}</Item.Description>
				<Item.Extra>
					<Button
						inverted
						floated="right"
						color="green"
						onClick={() => props.handleEdit(invoice._id)}
					>
						Editar
					</Button>
					<ConfirmModal
						id={invoice._id}
						title={'Delete Invoice'}
						message={`Are you sure that you want to delete the Invoice?`}
						triggerTitle={'Delete'}
						titleIcon='trash alternate'
						isDelete={true}
						onConfirm={props.handleDelete}
						floated='right'
					/>
				</Item.Extra>
			</Item.Content>
		</Item>
	);
};
