
export enum FileType {
    PDF = 'application/pdf',
    PNG = 'image/png',
    JPG = 'image/jpg',
    JPEG = 'image/jpeg'
}

export const isImageFile = (file: any) => {
    if (!file) return false;
    return file.type === FileType.JPEG ||
        file.type === FileType.JPG ||
        file.type === FileType.PNG
}

export const isPDFURL = (url: string) => {
    if (!url) return false;
    return url.indexOf('.pdf') !== -1;
}

export const isImageURL = (url: string) => {
    if (!url) return false;
    return url.indexOf('.jpg') !== -1 ||
        url.indexOf('.jpeg') !== -1 ||
        url.indexOf('.png') !== -1
}