import { MENU_SET_ACTIVE_ITEM, TOGGLE_SIDE_MENU } from '../../actions/menu/menu-types';

const INITIAL_STATE = {
    activeItem: 'dashboard',
    isVisibleSideMenu: true
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case MENU_SET_ACTIVE_ITEM:
            return { ...state, activeItem: action.payload };
        case TOGGLE_SIDE_MENU:
            return { ...state, isVisibleSideMenu: action.payload }
        default:
            return state;
    }
}