import * as Yup from 'yup';

export const expenseFormSchema = Yup.object().shape({
    invoicenumber: Yup.number()
        .notRequired(),
    date: Yup.date()
        .required('Date is Required!'),
    dueDate: Yup.date()
        .min(Yup.ref('date'), 'Due Date should be later than date!')
        .required('Due Date is Required!'),
    total: Yup.number()
        .positive('Needs to be grather than 0!')
        .required('Total is Required!'),
    subTotal: Yup.number()
        .required('Subtotal is required')
        .positive('Needs to be grather than 0!'),
    iva: Yup.number().notRequired(),
    notes: Yup.string()
        .max(250, 'Notas too long!')
        .notRequired(),
    imageURL: Yup.string()
        .url('Invalid vaucher url!'),
    // currency: Yup.string()
    //     .oneOf(['US$', '$'], 'Invalid currency!')
    //     .required('Currency is Required!'),
    userId: Yup.string()
        .required('User is Required!'),
    rows: Yup.array().of(
        Yup.object().shape({
            amount: Yup.number().required('Amount is Required!'),
            rate: Yup.number()
                .positive('Needs to be grather than 0!')
                .required('Rate is Required!'),
            quantity: Yup.number()
                .min(1, 'Needs to be grather or equal to 1!')
                .required('Quantity is Required!'),
            items: Yup.number().notRequired(),
            notes: Yup.string()
                .min(3, 'Notes too short!')
                .max(150, 'Notes too long!')
                .required('Notes is Required!')
        })
    ).notRequired(),
    category: Yup.object()
        .required('Category is Required!')
});