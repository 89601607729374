import { currencies } from '../../common/money/CurrencySelect'

export type Props = {
    onSubmit: Function;
    onClose: Function;
    onSubmitEdit: Function,
    initialValues?: any;
}

export interface InvoiceItem {
    _id: string,
    amount: number,
    rate: string,
    quantity: string,
    items: number,
    notes: string,
    invoiceId: string
}

export interface InvoiceFormValues {
    _id: string,
    invoicenumber: number,
    date: Date,
    dueDate: Date,
    total: number,
    subTotal: number,
    iva: number,
    concept: string,
    notes: string,
    voucherUrl: string,
    currency: string,
    rows: InvoiceItem[],
    userId: string,
    client: {
        _id: string,
        fullName: string
    },
    projectId: string
}

export const INITIAL_VALUES = {
    _id: undefined,
    invoicenumber: 0,
    date: new Date(),
    dueDate: new Date(),
    total: 0,
    subTotal: 0,
    iva: 0,
    concept: '',
    notes: '',
    voucherUrl: '',
    currency: currencies[1],
    rows: [
        {
            _id: undefined,
            amount: 0,
            rate: 0,
            quantity: 1,
            items: 1,
            notes: '',
            invoiceId: 0
        }
    ],
    userId: 0,
    client: {
        _id: null,
        fullName: undefined
    },
    projectId: null
}

export const ROWS_INITIAL_VALUES = {
    _id: undefined,
    amount: 0,
    rate: 0,
    quantity: 1,
    items: 1,
    notes: '',
    invoiceId: 0
}