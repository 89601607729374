import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { Button, Form, Segment } from 'semantic-ui-react';
import { loginFormSchema } from './validation';

type Props = {
    onSubmit?: any;
};

type State = {};

export interface LoginFormValues {
    email: string;
    pass: string;
}

export class LoginForm extends Component<Props, State> {
    INITIAL_VALUES = {
        email: "",
        pass: "",
    } as LoginFormValues;

    render() {
        return (
            <Segment textAlign='left'>
                <Formik
                    initialValues={this.INITIAL_VALUES}
                    onSubmit={(values, actions) => {
                        actions.resetForm();
                        this.props.onSubmit(values);
                    }}
                    validationSchema={loginFormSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        resetForm,
                        isSubmitting,
                        setFieldValue,
                        getFieldProps,
                    }) => (
                        <Form size="large" className='ui error' onSubmit={handleSubmit} loading={isSubmitting}>
                            <Field
                                name="email"
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="E-mail addres"
                                as={Form.Input}
                                error={errors.email && touched.email ? {
                                    content: errors.email,
                                    pointing: 'below'
                                }: null}
                            />
                            <Field
                                name="pass"
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Password"
                                type="password"
                                as={Form.Input}
                                error={errors.pass && touched.pass ? {
                                    content: errors.pass,
                                    pointing: 'below'
                                }: null}
                            />
                            <Button
                                type='submit'
                                color="green" 
                                fluid size="large">
                                Login
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Segment>
        );
    }
}
