import { AnyAction, combineReducers } from 'redux';
import AuthReducer from './auth/AuthReducer'
import MenuReducer from './menu/MenuReducer';
import UserReducer from './user/UserReducer';
import ClientReducer from './client/ClientReducer';
import InvoiceReducer from './invoice/InvoiceReducer';
import ExpenseReducer from './expense/ExpenseReducer';
import CategoryReducer from './categories/CategoryReducer';
import DashboarReducer from './dashboard/DashboardReducer';
import { API_SIGN_OUT } from '../actions/auth/auth-types';
import { clearStorage } from '../api/storage.api';

export const RootReducer = (state: any, action: AnyAction) => {
    if (action.type === API_SIGN_OUT) {
        clearStorage();
        return AppReducer(undefined, action)
    }
    return AppReducer(state, action)
  }

const AppReducer = combineReducers({
    auth: AuthReducer,
    menu: MenuReducer,
    user: UserReducer,
    entities: combineReducers({
        client: ClientReducer,
        invoice: InvoiceReducer,
        expense: ExpenseReducer,
        category: CategoryReducer,
        dashboard: DashboarReducer
    })
});