import { 
    API_GET_RESOURCE,
    API_GET_ALL_RESOURCE, 
    API_POST_RESOURCE,
    API_DELETE_RESOURCE,
    OPEN_CREATE_EDIT_MODAL,
    SET_RESOURCE_ID,
    SET_CURRENT_ENTITY,
    API_PUT_RESOURCE
} from '../../actions/crud/crud.types';
import _ from 'lodash';
import { RESOURCES } from '../../actions/crud/resources';

const INITIAL_STATE = {
    clients: [],
    currentClient: null,
    isOpenModal: false,
    currentId: null
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case `${API_GET_RESOURCE}_${RESOURCES.clients.toLocaleUpperCase()}`:
            return { ...state, currentClient: action.payload };
        case `${API_GET_ALL_RESOURCE}_${RESOURCES.clients.toLocaleUpperCase()}`:
            return { ...state, clients: action.payload };
        case `${API_POST_RESOURCE}_${RESOURCES.clients.toLocaleUpperCase()}`:
            return { ...state, currentClient: action.payload };
        case `${API_PUT_RESOURCE}_${RESOURCES.clients.toLocaleUpperCase()}`:
            return { ...state, currentClient: action.payload };            
        case `${API_DELETE_RESOURCE}_${RESOURCES.clients.toLocaleUpperCase()}`:
            const clients = _.filter(state.clients?.values, (client: any) => client._id !== action.payload);
            return {
				...state,
				clients: {
					...state.clients,
					values: clients
				}
			};
        case `${OPEN_CREATE_EDIT_MODAL}_${RESOURCES.clients.toLocaleUpperCase()}`:
            return { ...state, isOpenModal: action.payload };
        case SET_RESOURCE_ID:
            return { ...state, currentId: action.payload };
        case `${SET_CURRENT_ENTITY}_${RESOURCES.clients.toLocaleUpperCase()}`:
            return { ...state, currentClient: action.payload };                              
        default:
            return state;
    }
};