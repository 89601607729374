import { AxiosRequestConfig } from 'axios';
import api from '../../api/invoice.api';
import history from '../../History';
import { Toast } from '../../utils/ToastifyFactory';
import {
    API_GET_ALL_RESOURCE,
    API_GET_RESOURCE,
    API_POST_RESOURCE,
    API_PUT_RESOURCE,
    API_PATCH_RESOURCE,
    API_DELETE_RESOURCE, 
    OPEN_CREATE_EDIT_MODAL,
    SET_RESOURCE_ID,
    SET_CURRENT_ENTITY
} from './crud.types';
import { getQueryParams, QueryParams } from './crud.util';

export const getAllResources = (resource: string, query?: QueryParams) => async (dispatch: Function) => {
    console.log('Query: ', getQueryParams(query));
    const response = await api.get(`${resource}`, getQueryParams(query) as AxiosRequestConfig) as any;
    dispatch({
        type: `${API_GET_ALL_RESOURCE}_${resource.toLocaleUpperCase()}`,
        payload: response?.data.data
    });
}

export const findAllResourcesByUser = (resource: string) => async (dispatch: Function) => {
    const response = await api.get(`${resource}/findByUser`) as any;
    dispatch({
        type: `${API_GET_ALL_RESOURCE}_${resource.toLocaleUpperCase()}`,
        payload: response.data.data
    });
}

export const getResource = (resource: string, id: string, path?: string) => async (dispatch: Function) => {
    const response = await api.get(`${resource}/${id}`);
    dispatch({
        type: `${API_GET_RESOURCE}_${resource.toLocaleUpperCase()}`,
        payload: response.data
    });
    if (path) {
        history.push(path);
    }
}

export const postResource = 
(resource: string, formValues: any, goBack?: boolean) => async (dispatch: Function) => {
    const response = await api.post(resource, formValues) as any;
    dispatch({
        type: `${API_POST_RESOURCE}_${resource.toLocaleUpperCase()}`,
        payload: response.data
    });
    if (formValues.image) {
        try {
            await uploadImage(response.data.data._id, resource, formValues.image);
        } catch (error) {
            Toast.error('An error occurred when trying to upload the image!');
        }
    }
    if (goBack) {
        history.back();
    }
}

export const putResource = 
(resource: string, formValues: any, id: string, goBack?: boolean) => async (dispatch: Function) => {
    const response = await api.put(`${resource}/${id}`, formValues);
    dispatch({
        type: `${API_PUT_RESOURCE}_${resource.toLocaleUpperCase()}`,
        payload: response.data
    });
    if (goBack) {
        history.back();
    }
}

export const patchResource = 
(formValues: any, resource: string, id: string, path?: string) => async (dispatch: Function) => {
    const response = await api.patch(`${resource}/${id}`, formValues);
    dispatch({
        type: `${API_PATCH_RESOURCE}_${resource.toLocaleUpperCase()}`,
        payload: response.data
    });
    if (path) {
        history.push(path);
    }
}

export const deleteResource = (resource: string, id: string, path?: string) => async (dispatch: Function) => {
    await api.delete(`${resource}/${id}`);
    dispatch({
        type: `${API_DELETE_RESOURCE}_${resource.toLocaleUpperCase()}`,
        payload: id
    });
    if (path) {
        history.push(path);
    }
}

export const uploadImage = async (id: string, resource: string, file: File | null): Promise<any> => {
    const formData = new FormData();
    formData.append('image', file as File);
    return api.put(`${resource}/upload/${id}`, formData);
}

// Modal CRUD actions
export const openModal = (resource: string, open: boolean) => {
    return {
        type: `${OPEN_CREATE_EDIT_MODAL}_${resource.toLocaleUpperCase()}`,
        payload: open
    };
}

export const setResourceId = (id?: string) => {
    return {
        type: SET_RESOURCE_ID,
        payload: id
    };
}

export const setCurrentEntity = (resource: string, values: any) => {
    return {
        type: `${SET_CURRENT_ENTITY}_${resource.toLocaleUpperCase()}`,
        payload: values
    }
}

