import React, { Component, SyntheticEvent } from 'react';
import {
	Segment,
	Header,
	Grid,
	Tab,
	TabProps
} from 'semantic-ui-react';
import { ExpenseTable } from './ExpenseTable';
import { connect } from 'react-redux';
import {
    getAllResources,
    getResource,
    deleteResource,
	setCurrentEntity,
	openModal
} from '../../../actions/crud/crud.actions';
import { RESOURCES } from '../../../actions/crud/resources';
import { map } from 'lodash';
import { ExpenseFilter } from './ExpenseFilter';
import { parseStartDate, parseEndDate } from '../../../utils/DateUtil';
import { Toast } from '../../../utils/ToastifyFactory';
import ExpenseCalculator from './expense-calculator/ExpenseCalculator';

const INITIAL_FILTER = {
	notes: null,
	'category._id': '',
	startDate: null,
	endDate: null
};

class ExpensePage extends Component<any, any> {

	filters = INITIAL_FILTER;
    state = {
        activeItem: 'expenses'
    };

	getQueryParams = () => {
		return { filters: this.buildFilters(), sort: { date: -1 }, limit: 10 };
	}
    
    async componentDidMount() {
		await this.props.getAllResources(RESOURCES.expenses, { sort: { date: -1 }, limit: 10});
		await this.props.getAllResources(RESOURCES.categories);
    }

    handleCreate = () => {
        this.props.setCurrentEntity(RESOURCES.expenses, null);
    }

    handleEdit = async (id: string | undefined): Promise<void> => {
		await this.props.getResource(RESOURCES.expenses, id);
		this.props.openModal(RESOURCES.expenses, !this.props.isOpenModal);
    }

    handleDelete = async (id: string): Promise<void> => {
        await this.props.deleteResource(RESOURCES.expenses, id);
		Toast.success('Expense deleted successfully!');
    }

	handleItemClick = (e: any, props: any) => this.setState({ activeItem: props.name });
        
    handlePageChange = async (e: SyntheticEvent, data: any): Promise<void> => {
		await this.props.getAllResources(RESOURCES.expenses, {
			...this.getQueryParams(),
			page: data.activePage
		});
    }

	handleSelectCategory = async (e: SyntheticEvent, data: any): Promise<void> => {
		this.filters['category._id'] = data.value;
		const filters = this.buildFilters();
		if (data.value === 0) {
			delete filters['category._id'];
		}
		await this.props.getAllResources(RESOURCES.expenses, this.getQueryParams());
	}

	handleSearch = async (e: SyntheticEvent, data: any): Promise<void> => {
		this.filters.notes = { $regex: '.*' + data.value + '.*' } as any;
		await this.props.getAllResources(RESOURCES.expenses, this.getQueryParams());
	}

	handleStartDateChange = async (date: Date) => {
		this.filters.startDate = date as any;
		await this.props.getAllResources(RESOURCES.expenses, this.getQueryParams());
	}

	handleEndDateChange = async (date: Date) => {
		this.filters.endDate = date as any;
		await this.props.getAllResources(RESOURCES.expenses, this.getQueryParams());
	}

	handleRefresh = async () => {
		this.filters = INITIAL_FILTER;
		await this.props.getAllResources(RESOURCES.expenses, { sort: { date: -1 }, limit: 10 });
	}

	handleClose = async (submit: boolean) => {
		if (submit) {
			await this.props.getAllResources(RESOURCES.expenses, this.getQueryParams());
		}
	}

	buildFilters = (): any => {
		const filters = this.filters as any;
		const finalFilter = {} as any;
		if (filters.notes) { finalFilter.notes = filters.notes };
		if (filters['category._id']) { finalFilter['category._id'] = filters['category._id'] };
		if (filters.startDate) {
			if (finalFilter.date) { finalFilter.date.$gte = parseStartDate(filters.startDate) }
			else { finalFilter.date = { $gte: parseStartDate(filters.startDate) } };
		};
		if (filters.endDate) {
			if (finalFilter.date) { finalFilter.date.$lte = parseEndDate(filters.endDate) }
			else { finalFilter.date = { $lte: parseEndDate(filters.endDate) } };
		};
		return finalFilter;
	}

	handleTabChange = (event: SyntheticEvent, data: TabProps) => {
		console.log(data.activeIndex);
		this.setState({ activeItem: data.activeIndex === 0 ? 'expenses' : null })
	}

	render() {
		const style = { backgroundColor: '#f5f5f5' };
		const { activeItem } = this.state;
		const allOption = [{ key: 0, value: 0, text: 'All' }];
		const options = map(this.props.categories, (category: any) => {
			return {
				key: category._id,
				value: category._id,
				text: category.name
			}
		});
		const categoriesOptions = allOption.concat(options);
			const panes = [
				{
				  menuItem: 'Expenses',
				  render: () => <Tab.Pane attached={false}>
					<ExpenseTable
						handlePageChange={this.handlePageChange}
						handleCreate={this.handleCreate}
						handleEdit={this.handleEdit}
						handleDelete={this.handleDelete}
						expenses={this.props.expenses}
						categories={this.props.categories}
						handleClose={this.handleClose}
					/>
				  </Tab.Pane>,
				},
				{
				  menuItem: 'Calculator',
				  render: () => <Tab.Pane attached={false}><ExpenseCalculator/></Tab.Pane>,
				}
			  ]
		let render = (
			<Segment color="green" style={style}>
				<Grid>
					<Grid.Column stretched>
						{ activeItem === 'expenses' ?
							<ExpenseFilter
								categoriesOptions={categoriesOptions}
								handleSelectCategory={this.handleSelectCategory}
								handleSearch={this.handleSearch}
								handleStartDateChange={this.handleStartDateChange}
								handleEndDateChange={this.handleEndDateChange}
								handleRefresh={this.handleRefresh}
							/>: null
						}
						<Segment>
							<Tab
								menu={{ pointing: true, color: 'green', inverted: true }}
								panes={panes}
								onTabChange={this.handleTabChange}
							/>
						</Segment>
					</Grid.Column>
				</Grid>
			</Segment>
		);
		return (
			<div>
				<Segment clearing color="green" style={style}>
					<Header as="h2" floated="left">
						Expenses
					</Header>
					{/* <ExpenseModal color='green' categories={this.props.categories} onClose={this.props.handleClose}/> */}
				</Segment>
				{render}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
    return {
		expenses: state.entities.expense?.expenses,
		categories: state.entities.category?.categories,
		loggedUser: state.auth.loggedUser
    };
};

export default connect(mapStateToProps, {
    getAllResources,
    getResource,
    deleteResource,
	setCurrentEntity,
	openModal
})(ExpensePage);
