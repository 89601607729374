import React, { Component } from 'react';
import { Segment, Header, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { InvoiceForm } from './InvoiceForm';
import { connect } from 'react-redux';
import {
    getAllResources,
    postResource,
    putResource,
    uploadImage
} from '../../../actions/crud/crud.actions';
import { RESOURCES } from '../../../actions/crud/resources';

class InvoiceSave extends Component<any, any> {

    async componentDidMount() {
        await this.props.getAllResources(RESOURCES.clients);
    }

    onUploadImage = async (id: string, file: any) => {
        await uploadImage(id, RESOURCES.invoices, file);
        await this.props.getAllResources(RESOURCES.invoices, { sort: { date: -1 } });
    }

    render() {
        const style = { backgroundColor: '#f5f5f5' };
        return (
            <div>
                <Segment clearing color='green' style={style}>
                    <Header as='h2' floated='left'>
                        Save Invoice
                    </Header>
                    <Button
                        as={Link}
                        to='/invoices'
                        icon
                        color='green'
                        labelPosition='left'
                        floated='right'
                    >
                        <Icon name='long arrow alternate left' /> Back
                    </Button>
                </Segment>
                <Segment color='green' padded>
                    <InvoiceForm
                        clients={this.props.clients?.values}
                        loggedUser={this.props.loggedUser}
                        initialValues={this.props.currentInvoice}
                        onSubmit={this.props.postResource}
                        onSubmitEdit={this.props.putResource}
                        onUploadImage={this.onUploadImage}
                    />
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentInvoice: state.entities.invoice.currentInvoice,
        currentId: state.entities.invoice.currentId,
        clients: state.entities.client.clients,
        loggedUser: state.auth.loggedUser
    };
};

export default connect(mapStateToProps, {
    getAllResources,
    postResource,
    putResource
})(InvoiceSave);
