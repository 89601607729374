import React, { Component } from 'react';
import { Grid, Form, Button, Divider } from 'semantic-ui-react';
import { Formik, Field, FieldArray } from 'formik';
import { InvoiceItemForm } from './InvoiceItemForm';
import { INITIAL_VALUES } from './helper';
import DatePicker from 'react-datepicker';
import { CustomDateInput } from '../../custom/date/CustomDateInput';
import SearchInput from '../../custom/search/SearchInput';
import { InvoiceTotalForm } from './InvoiceTotalForm';
import { invoiceFormSchema } from './validation';
import { RESOURCES } from '../../../actions/crud/resources';
import { FileInput } from '../../custom/image/FileInput';
import { Toast } from '../../../utils/ToastifyFactory';

export class InvoiceForm extends Component<any, any> {

    state = {
        selectedImage: null,
        loading: false
    };

    handleClientSelect = (result: any, setFieldValue: Function) => {
        setFieldValue('client.fullName', result.title);
        setFieldValue('client._id', result.id);
    }

    onSelectImage = (imageFile: File) => {
        this.setState({
            selectedImage: imageFile
        });
    }

    uploadImage = async (id: string) => {
        try {
            if (this.state.selectedImage) {
                this.setState({ loading: true });
                await this.props.onUploadImage(id, this.state.selectedImage);
            }
        } catch (error) {
            this.setState({ loading: false });
            console.log(error);
            Toast.error('An error occurred when trying to upload the image!');
        }
    }

    render() {
        const date = new Date();
		INITIAL_VALUES.date = date;
		INITIAL_VALUES.dueDate = date;
        const invoice = this.props.initialValues ? this.props.initialValues.data : INITIAL_VALUES;
        const userFullName = `${this.props.loggedUser.name} ${this.props.loggedUser.surname}`;
        invoice.userId = this.props.loggedUser._id;
        return (
            <Formik
                initialValues={invoice}
                validationSchema={invoiceFormSchema}
                onSubmit={async (values, actions) => {
                    values.image = this.state.selectedImage;
                    if (invoice._id) {
                        await this.props.onSubmitEdit(RESOURCES.invoices, values, invoice._id, true);
                    } else {
                        await this.props.onSubmit(RESOURCES.invoices, values, RESOURCES.invoices);
                    }
                    Toast.success('Invoice saved successfully!');
                    if (invoice._id && this.state.selectedImage) {
                        await this.uploadImage(invoice._id);
                    }
                }}
            >
                {({ values, errors, touched, handleSubmit, resetForm, isSubmitting, setFieldValue }) => (
                    <Form
                        size='large'
                        className='ui error'
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                    >
                        <Grid divided>
                            <Grid.Row>
                                <Grid.Column computer={5} tablet={5} mobile={16} style={{ paddingBottom: '10px' }} floated='right'>
                                    <Grid.Row columns={1}>
                                        <SearchInput
                                            selected={invoice.client}
                                            values={this.props.clients}
                                            setFieldValue={setFieldValue}
                                            handleResultSelect={this.handleClientSelect}
                                            input={
                                                <Field
                                                    name='client.fullName'
                                                    icon='user secret'
                                                    iconPosition='left'
                                                    fluid
                                                    placeholder='To...'
                                                    as={Form.Input}
                                                    error={ errors.client && touched.client ? {
                                                        content: 'Este campo es requerido!',
                                                        pointing: 'below'
                                                    } : null }
                                                />
                                            }
                                        />
                                        <Divider/>
                                        <Field
                                            name='userName'
                                            readOnly
                                            value={userFullName}
                                            fluid
                                            icon='user'
                                            iconPosition='left'
                                            placeholder='From...'
                                            as={Form.Input}
                                            error={ errors.userName && touched.userName ? {
                                                content: errors.userName,
                                                pointing: 'below'
                                            } : null }
                                        />
                                        <Divider/>
                                    </Grid.Row>
                                    <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <label><b>Date:</b></label>
                                                <DatePicker
                                                    name='date'
                                                    selected={new Date(values.date)}
                                                    dateFormat='dd/MM/yyyy'
                                                    showYearDropdown
                                                    customInput={
                                                        <CustomDateInput
                                                            style={{marginTop: 5}}
                                                            error={errors.date}
                                                            touched={touched.date}
                                                            value={values.date}
                                                        />
                                                    }
                                                    onChange={(date) => setFieldValue('date', date)}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <label><b>Due Date:</b></label>
                                                <DatePicker
                                                    name='dueDate'
                                                    selected={new Date(values.dueDate)}
                                                    dateFormat='dd/MM/yyyy'
                                                    showYearDropdown
                                                    customInput={
                                                        <CustomDateInput
                                                            style={{marginTop: 5}}
                                                            error={errors.dueDate}
                                                            touched={touched.dueDate}
                                                            value={values.dueDate}
                                                        />
                                                    }
                                                    onChange={(date) => setFieldValue('dueDate', date)}
                                                ></DatePicker>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <FileInput
                                                    url={invoice.imageURL}
                                                    onSelectImage={this.onSelectImage}
                                                    ></FileInput>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>                                
                                <Grid.Column computer={11} tablet={5} mobile={16} style={{ paddingBottom: '10px' }} floated='left'>
                                    <FieldArray 
                                        name='rows'
                                        render={InvoiceItemForm}
                                    />
                                    <Divider />
                                    <Grid divided>
                                        <Grid.Row>
                                            <Grid.Column computer={10} tablet={10} mobile={16}>
                                                <Field
                                                    name='notes'
                                                    label='Notes'
                                                    placeholder='Write a note here...'
                                                    as={Form.TextArea}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer={6} tablet={6} mobile={16}>
                                                <InvoiceTotalForm 
                                                    form={values}
                                                    setFieldValue={setFieldValue}
                                                    errors={errors} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>                                
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button.Group style={{float: 'right'}}>
                                        <Button
                                            color='grey'
                                            type='reset'
                                            onClick={() => resetForm() }
                                        >Close</Button>
                                        <Button.Or />
                                        <Button
                                            type='submit' 
                                            positive
                                        >Save</Button>
                                    </Button.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    }
}