import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { Form, Button, Grid, Dropdown, Checkbox } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { CustomDateInput } from '../../custom/date/CustomDateInput';
import { RESOURCES } from '../../../actions/crud/resources';
import { INITIAL_VALUES } from './helper';
import { filter, map } from 'lodash';
import { Toast } from '../../../utils/ToastifyFactory';
import { ToastContainer } from 'react-toastify';
import { expenseFormSchema } from './validations';
import { ExpenseTotalForm } from './ExpenseTotalForm';
import { UploadImagePDFInput } from '../../custom/image/UploadImagePDFInput';

export class ExpenseForm extends Component<any, any> {

	state = {
        selectedImage: null,
        loading: false
    };

	onSelectImage = (imageFile: File) => {
        this.setState({
            selectedImage: imageFile
        });
    }

    uploadImage = async (id: string) => {
        try {
            if (this.state.selectedImage) {
                this.setState({ loading: true });
                await this.props.onUploadImage(id, this.state.selectedImage);
            }
        } catch (error) {
            this.setState({ loading: false });
            console.log(error);
            Toast.error('An error occurred when trying to upload the image!');
        }
    }

	render() {
		const date = new Date();
		INITIAL_VALUES.date = date;
		INITIAL_VALUES.dueDate = date;
		const expense = this.props.initialValues ? this.props.initialValues.data
			: INITIAL_VALUES;
		expense.userId = this.props.loggedUser?._id;
		const categoriesOptions = map(this.props.categories, (category: any) => {
			return {
				key: category._id,
				value: category._id,
				text: category.name
			}
		});		
		return (
			<Formik
				initialValues={expense}
				validationSchema={expenseFormSchema}
				onSubmit={async (values, actions) => {
					const data = {...values};
					try {
						if (expense._id) {
							delete data._id;
							await this.props.onSubmitEdit(
								RESOURCES.expenses, values, expense._id
							);
						} else {
							values.image = this.state.selectedImage;
							await this.props.onSubmit(RESOURCES.expenses, values);
						}
						Toast.success('Expense saved successfully!');
						if (expense._id && this.state.selectedImage) {
							await this.uploadImage(expense._id);
						}
						actions.resetForm();
						await this.props.onClose(true);						
					} catch (error) {
						Toast.error('An error occurred while saving the expense!')
					}
				}}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					resetForm,
					isSubmitting,
					setFieldValue
				}) => (
					<Form
						size="large"
						className="ui error"
						onSubmit={handleSubmit}
						loading={isSubmitting}
					>
						<ToastContainer />
						<Grid divided>
							<Grid.Row columns={2}>
								<Grid.Column>
									<UploadImagePDFInput
										circular={false}
										onSelect={this.onSelectImage}
										url={expense.imageURL}
									/>
									{/* <UploadImageInput 
										circular={false}
										onSelectImage={this.onSelectImage}
										imageURL={expense.imageURL}
									/> */}
								</Grid.Column>
								<Grid.Column>
									<Grid>
										<Grid.Row columns={2}>
											<Grid.Column>
												<label><b>Date:</b></label>
												<DatePicker
													name="date"
													selected={ new Date(values.date) }
													dateFormat="dd/MM/yyyy"
													showYearDropdown
													customInput={
														<CustomDateInput
															style={{marginTop: 5}}
															error={errors.date}
															touched={touched.date}
															value={values.date}
														/>
													}
													onChange={(date: any) => setFieldValue('date', date)}
												/>
											</Grid.Column>
											<Grid.Column>
												<label><b>Due Date:</b></label>
												<DatePicker
													name="dueDate"
													selected={ new Date(values.dueDate) }
													dateFormat="dd/MM/yyyy"
													showYearDropdown
													customInput={
														<CustomDateInput
															style={{marginTop: 5}}
															error={errors.dueDate}
															touched={touched.dueDate}
															value={values.dueDate}
														/>
													}
													onChange={(date: any) => setFieldValue('dueDate', date) }
												/>
											</Grid.Column>
										</Grid.Row>
									</Grid>
									<br/>
									<Grid.Row>
										<Grid.Column>
											<Dropdown
											    placeholder='Select Category'
												fluid
												selection
												options={categoriesOptions}
												onChange={(e: any, data: any) => {
													const category = filter(this.props.categories,
														(c) => c._id === data.value).pop();
													setFieldValue('category', category);
												}}
												value={values.category?._id}
												error={errors.category && touched.category ? true : false}
											/>
											<br/>
											<ExpenseTotalForm
												setFieldValue={setFieldValue}
												form={values}
												errors={errors}
												touched={touched}
											/>
											<br />
											<Field
												name="notes"
												label="Notes"
												placeholder="Write a note here..."
												as={Form.TextArea}
											/>
											<Field
												name='isPaid'
												label='Is Paid?'
												component={Checkbox}
												checked={values.isPaid}
												onChange={(e:any, data: any) => setFieldValue('isPaid', data.checked)}
											/>
										</Grid.Column>
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<br />
						<br />
						<Button.Group style={{ float: 'right' }}>
							<Button
								color="grey"
								type="reset"
								onClick={() => {
									resetForm();
									this.props.onClose();
								}}
							>
								Close
							</Button>
							<Button.Or />
							<Button type="submit" positive>Save</Button>
						</Button.Group>
					</Form>
				)}
			</Formik>
		);
	}
}
