import React from 'react';
import { connect } from 'react-redux';
import { Card, Grid, Segment } from 'semantic-ui-react';
import { DashboardLineChart } from './DashboardLineChart';
import { DashboardPieChart } from './DashboardPieChart';
import { DashboardBarChart } from './DashboardBarChart';
import DashboardStatisticsHeader from './DashboardStatisticsHeader';
import {
    getLineChartData,
    getPieChartData,
    getBarChartData
} from '../../../actions/dashboard/dashboard.actions';
import DashboardLastInfo from './DashboardLastInfo';

type Props = {
    getPieChartData: Function,
    getLineChartData: Function,
    getBarChartData: Function,
    expensesChart: any,
    invoicesChart: Array<any>,
    barChart: Array<any>
};

type State = {};

class DashboardPage extends React.Component<Props, State> {

    async componentDidMount() {
        await this.props.getLineChartData();
        await this.props.getPieChartData();
        await this.props.getBarChartData();
    }

	render() {
        const style = { backgroundColor: '#f5f5f5' };
        console.log(this.props);
		return (
			<div>
                <DashboardStatisticsHeader/>
				<Segment color='green' style={style}>
					<Grid>
						<Grid.Row>
							<Grid.Column width={10}>
								<Card fluid>
                                    <Card.Content>
                                        <Card.Header>Invoice by Month</Card.Header>
                                    </Card.Content>
                                    <Card.Content>
                                        <DashboardLineChart data={this.props.invoicesChart}/>
                                    </Card.Content>
								</Card>
							</Grid.Column>
							<Grid.Column width={6}>
                                <Card fluid>
                                    <Card.Content>
                                        <Card.Header>Expenses by Category</Card.Header>
                                    </Card.Content>
                                    <Card.Content>
                                        <DashboardPieChart data={this.props.expensesChart?.uy} />
                                    </Card.Content>
                                </Card>
							</Grid.Column>
						</Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Card fluid>
                                    <Card.Content>
                                        <Card.Header>Expenses by Category and Month</Card.Header>
                                    </Card.Content>
                                    <Card.Content>
                                        <DashboardBarChart data={this.props.barChart}/>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Card fluid>
                                    <Card.Content>
                                        <Card.Header>Last Expenses and Invoices</Card.Header>
                                    </Card.Content>
                                    <Card.Content>
                                        <DashboardLastInfo />
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                        </Grid.Row>
					</Grid>
				</Segment>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
    return {
        expensesChart: state.entities.dashboard.expensesChart,
        invoicesChart: state.entities.dashboard.invoicesChart,
        barChart: state.entities.dashboard.barChart
    };
};

export default connect(mapStateToProps, {
    getLineChartData,
    getPieChartData,
    getBarChartData
})(DashboardPage);