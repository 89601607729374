import { toast, ToastOptions } from 'react-toastify';

export const TOAST_POSITION = toast.POSITION.TOP_CENTER;

export class Toast {

    static success(msg: string, config?: ToastOptions | undefined): void {
        toast.success(msg, {
            position: toast.POSITION.TOP_CENTER,
            theme: 'colored'
        });
    }

    static info(msg: string, config?: ToastOptions | undefined): void {
        toast.info(msg, {
            position: toast.POSITION.TOP_CENTER,
            theme: 'colored'
        });
    }

    static warn(msg: string, config?: ToastOptions | undefined): void {
        toast.warn(msg, {
            position: toast.POSITION.TOP_CENTER,
            theme: 'colored'
        });
    }

    static error(msg: string, config?: ToastOptions | undefined): void {
        toast.error(msg, {
            position: toast.POSITION.TOP_CENTER,
            theme: 'colored'
        });
    }

}