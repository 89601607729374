import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';

type Props = {

};

type State = {

};

export class WorkGroupsPage extends Component<Props, State> {

    render() {
        return (
            <div>
                <h1>Work Groups Page!</h1>
                <Card fluid color='green' header='Work Groups Page Content' style={{paddingRight: '15px'}}/>
            </div>
        )
    }

}