import history from '../../History';
import { API_GET_PROFILE, API_PUT_PROFILE } from './user.types';
import api from '../../api/invoice.api';
import { ProfileFormValues } from '../../components/pages/profile/ProfileForm';

const RESOURCE = 'users';

export const getProfile = () => async (dispatch: Function) => {
    const response = await api.get(`${RESOURCE}/profile`);
    dispatch({
        type: API_GET_PROFILE,
        payload: response?.data
    });
}

export const updateProfile = (profile: ProfileFormValues) => async (dispatch: Function) => {
    const response = await api.put(`${RESOURCE}/profile`, profile);
    dispatch({
        type: API_PUT_PROFILE,
        payload: response?.data
    });
}

export const uploadProfileImage = async (file: File | null): Promise<any> => {
    const formData = new FormData();
    formData.append('image', file as File);
    return await api.put(`${RESOURCE}/upload`, formData);
}

export const goToProfile = () => {
    history.push('/profile');
    getProfile();
}