import DashboardPage from '../../pages/dashboard/DashboardPage';
import ClientPage from '../../pages/client/ClientPage';
import InvoicePage from '../../pages/invoice/InvoicePage';
import { WorkGroupsPage } from '../../pages/work-group/WorkGroupPage';
import ProfilePage from '../../pages/profile/ProfilePage';
import InvoiceSave from '../../pages/invoice/InvoiceSave';
import ExpensePage from '../../pages/expense/ExpensePage';

export const menu = {
    noMenuItems: [
        // Invoice
        {
            to: '/invoices/save',
            Component: InvoiceSave
        },
        {
            to: '/invoices/:id/save',
            Component: InvoiceSave
        }
    ],
    items: [
        {
            key: 1,
            itemName: 'dashboard',
            name: 'Dashboard',
            iconName: 'dashboard',
            to: '/dashboard',
            Component: DashboardPage
        },
        {
            key: 2,
            itemName: 'profile',
            name: 'Profile',
            iconName: 'user',
            to: '/profile',
            Component: ProfilePage
        },
        {
            key: 3,
            itemName: 'clients',
            name: 'Clients',
            iconName: 'user secret',
            to: '/clients',
            Component: ClientPage
        },
        {
            key: 4,
            itemName: 'invoices',
            name: 'Invoices',
            iconName: 'money',
            to: '/invoices',
            Component: InvoicePage
        },
        {
            key: 5,
            itemName: 'expenses',
            name: 'Expenses',
            iconName: 'dollar sign',
            to: '/expenses',
            Component: ExpensePage
        },
        {
            key: 6,
            itemName: 'groups',
            name: 'Work Groups',
            iconName: 'group',
            to: '/work-groups',
            Component: WorkGroupsPage
        }
    ],
    visibility: {
        dashboard: true,
        profile: true,
        clients: true,
        invoices: true,
        expenses: true,
        groups: true
    }
};