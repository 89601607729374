import { format } from 'date-fns';
import { Checkbox, Input, Message, Table } from 'semantic-ui-react';
import { isExpired } from '../../../../utils/DateUtil';
import { TotalAmount } from '../../../common/money/TotalAmount';
import { filter, isEmpty, isEqual, reduce, some } from 'lodash';
import { Component, ReactNode, SyntheticEvent } from 'react';


export class ExpenseCalculatorTable extends Component<any, any> {

    state = {
        total: 0,
        discount: 0,
        currentDiscount: 0,
        selectedExpenses: null,
        expenses: null
    };

    componentDidMount() {
        const expenses = this.props.expenses?.values;
        this.setState({
            expenses,
            selectedExpenses: expenses,
            total: this.calculateExpenses(expenses)
        });
    }

    static getDerivedStateFromProps(props: any, state: any) {
        const expenses = props.expenses?.values;
        if (!isEmpty(expenses) && !isEqual(expenses, state.expenses)) {
            const total = reduce(expenses, (sum: number, e: any) => {
                const discount = e.discount || 0;
                const total = e.total - discount;
                return (sum + total)
            }, 0) / 2;
            return ({
                expenses,
                selectedExpenses: expenses,
                total 
            });
        }
        return null;
    }

    handleToggleExpense = (e: any, data: any) => {
        const checked = data.checked;
        if (this.state.selectedExpenses) {
            let selectedExpenses = this.state.selectedExpenses as any[];
            if (checked) {
                selectedExpenses.push(e);
            } else {
                selectedExpenses = filter(selectedExpenses, expense => expense._id !== e._id);
            }
            this.setState({
                selectedExpenses,
                total: this.calculateExpenses(selectedExpenses) - this.state.discount
            });
        }
    }

    handleDiscountChange = (discount: number) => {
        const currentDiscount = discount;
        if (currentDiscount) {
            this.setState({ currentDiscount });
        }
    }

    calculateExpenses = (selectedExpenses: any): number => {
        const total = reduce(selectedExpenses, (sum: number, e: any) => {
            const discount = e.discount || 0;
            const total = e.total - discount;
            return (sum + total)
        }, 0); 
        return total / 2
    }

    setDiscount = (): void => {
        const discount = this.state.discount + this.state.currentDiscount;
        const total = this.calculateExpenses(this.state.selectedExpenses) - discount;
        this.setState({
            discount,
            total
        });
    }

    render(): ReactNode {
        const expenses = this.props.expenses?.values;
        const selectedExpenses = this.state.selectedExpenses;
        if (isEmpty(expenses)) {
            return (
                <div>
                    <Message info>
                        <Message.Header>There are no expenses to show!</Message.Header>
                        <p>Select a month to see expenses...</p>
                    </Message>
                </div>
            );
        }
        return (
            <Table compact celled definition selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Category</Table.HeaderCell>
                        <Table.HeaderCell>Notes</Table.HeaderCell>
                        <Table.HeaderCell style={{ textAlign: 'center' }}>
                            Total Amount
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {expenses
                        ? expenses.map((expense: any, index: number) => {
                                const discount = expense.discount || 0;
                                const total = expense.total - discount;
                                return (
                                    <Table.Row key={index} 
                                        negative={isExpired(expense.dueDate) && !expense.isPaid}
                                        warning={!expense.isPaid && !isExpired(expense.dueDate)}
                                        positive={expense.isPaid}>
                                        <Table.Cell collapsing>
                                            <Checkbox
                                                checked={some(selectedExpenses, (e: any) => e._id === expense._id)}
                                                slider
                                                onChange={(e, data) => this.handleToggleExpense(expense, data)}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>{format(new Date(expense.date), 'dd/MM/yyyy')}</Table.Cell>
                                        <Table.Cell>{expense.category?.name}</Table.Cell>
                                        <Table.Cell>{expense.notes}</Table.Cell>
                                        <Table.Cell style={{ textAlign: 'center' }}>
                                            <TotalAmount
                                                currency={expense.currency}
                                                total={total}
                                                color={'red'}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                          }) : null}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                        <Table.HeaderCell>
                            <Input
                                fluid
                                action={{
                                    color: 'green',
                                    labelPosition: 'right',
                                    icon: 'money',
                                    content: 'Discount',
                                    onClick: () => this.setDiscount()
                                }}
                                // value={this.state.discount}
                                onChange={(e: SyntheticEvent, data: any) => this.handleDiscountChange(+data.value)}
                                type='number'
                                icon='money'
                                iconPosition='left'
                                labelPosition='right'
                                placeholder='Enter discunt'
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ textAlign: 'center' }}>
                            <TotalAmount
                                currency={{ symbol: '$' }}
                                total={this.state.total}
                                size='big'
                                color='red' />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );
    }
};
