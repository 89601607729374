import React, { Component } from "react";
import { Icon, Menu, Sidebar, Transition } from "semantic-ui-react";
import { setMenuActiveItem } from "../../../actions/menu/menu-actions";
import { connect } from "react-redux";
import { PageContainer } from '../container/Container';
import { Link } from "react-router-dom";
import { menu } from './menu';
import { unstable_HistoryRouter as HistoryBrowser } from 'react-router-dom';
import history from '../../../History';

type Props = {
    isVisibleSideMenu?: boolean;
    activeItem?: string;
    setMenuActiveItem?: any;
};

type State = {
    isVisibleSideMenu: boolean;
    activeItem: string;
    visibles: any;
};

class SideBarMenu extends Component<Props, State> {
    state = {
        isVisibleSideMenu: this.props.isVisibleSideMenu,
        activeItem: this.props.activeItem,
        visibles: menu.visibility,
    } as State;

    toggleVisibility = (name: any) => {
        if (name !== this.state.activeItem) {
            let itemVisibleObj: any;
            itemVisibleObj = { visibles: { ...this.state.visibles } };
            itemVisibleObj.visibles[name] = !itemVisibleObj.visibles[name];
            this.setState((prevState) => ({ ...itemVisibleObj }));
        }
    };

    handleSidebarItemClick = (e: any, { name }: any) => {
        this.setState({ ...this.state, activeItem: name });
        this.toggleVisibility(name);
        this.props.setMenuActiveItem(name);
    };

    render() {
        const { isVisibleSideMenu, activeItem } = this.props;
        const { visibles } = this.state;
        const animation = "jiggle";
        const items = menu.items.map((i, index) => {
            return (
                <Transition
                    key={i.key}
                    animation={animation}
                    visible={(visibles as any)[i.itemName]}
                >
                    <Menu.Item
                        as={Link}
                        to={i.to}
                        name={i.itemName}
                        active={activeItem === i.itemName}
                        onClick={this.handleSidebarItemClick}
                    >
                        <Icon
                            name={i.iconName as any}
                            className={
                                activeItem === i.itemName ? "menu-selected" : ""
                            }
                        />
                        <span
                            className={
                                activeItem === i.itemName ? "menu-selected" : ""
                            }
                        >
                            {i.name}
                        </span>
                    </Menu.Item>
                </Transition>
            );
        });
        return (
            <HistoryBrowser history={history as any}>
                <Sidebar.Pushable>
                    <Sidebar
                        as={Menu}
                        animation="push"
                        icon="labeled"
                        inverted
                        vertical
                        visible={isVisibleSideMenu}
                        width="thin"
                    >
                        {items}
                    </Sidebar>
                    <Sidebar.Pusher>
                        <div className='page-container'>
                            <PageContainer />
                        </div>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </HistoryBrowser>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        activeItem: state.menu.activeItem,
        isVisibleSideMenu: state.menu.isVisibleSideMenu,
    };
};
export default connect(mapStateToProps, { setMenuActiveItem })(SideBarMenu);
