import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { menu } from '../common/menu/menu';
import _ from 'lodash';

export class AppRouter extends Component {
    render() {
        const items = _.concat(menu.items, menu.noMenuItems as any);
        return (
            <TransitionGroup component={'div'}>
                <CSSTransition
                    timeout={1000}
                    classNames='fade'
                >
                    <Routes>
                        {items.map(({ to, Component }) => (
                            <Route key={to} path={to} element={<Component />}/>                  
                        ))}
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
        );
    }
}
