import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Message } from 'semantic-ui-react';

export const DashboardBarChart = (props: any) => {
	if (props?.data?.values.length === 1 || props?.data?.values.length < 1) {
		return (
			<Message info>
				<Message.Header>There are no expenses to show!</Message.Header>
				<p>You can add expenses with the button located on the menu</p>
			</Message>
		);
	}
	return (
		<div style={{ height: 350 }}>
			<ResponsiveBar
				data={props.data?.values || []}
				keys={props.data?.keys || []}
				indexBy="yearMonth"
				margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={{ scheme: 'nivo' }}
				borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'Year-month',
					legendPosition: 'middle',
					legendOffset: 32,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legendPosition: 'middle',
					legendOffset: -40,
				}}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 120,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 100,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1,
								},
							},
						],
					},
				]}
			/>
		</div>
	);
};
