import { API_GET_PROFILE, API_PUT_PROFILE } from "../../actions/user/user.types";

const INITIAL_STATE = {
    loggedUser: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case API_GET_PROFILE:
            return { ...state, loggedUser: action.payload };
        case API_PUT_PROFILE:
            return { ...state, loggedUser: action.payload };
        default:
            return state;
    }
};
