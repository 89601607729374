import { currencies } from '../../common/money/CurrencySelect';

export type Props = {
    onSubmit: Function;
    onClose: Function;
    onSubmitEdit: Function,
    initialValues?: any;
}

export interface ExpenseItem {
    _id: string,
    amount: number,
    rate: string,
    quantity: string,
    items: number,
    notes: string,
    invoiceId: string
}

export interface ExpenseFormValues {
    _id: string | undefined,
    expensenumber: number,
    date: Date,
    dueDate: Date,
    total: number,
    subTotal: number,
    iva: number,
    notes: string,
    imageURL?: string,
    currency: any,
    rows: ExpenseItem[],
    userId: string,
    category: any,
    isPaid?: boolean
}

export const INITIAL_VALUES = {
    _id: undefined,
    expensenumber: 0,
    date: new Date(),
    dueDate: new Date(),
    total: 0,
    subTotal: 0,
    discount: 0,
    iva: 0,
    notes: '',
    currency: currencies[1],
    rows: [],
    userId: '',
    category: null,
    isPaid: false
} as ExpenseFormValues;