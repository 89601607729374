import _ from 'lodash';

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state') as string;
        if (_.isEmpty(serializedState)) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

export const saveState = (state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (error) {
        console.log(error);
    }
};

export const saveItem = (item: any, key: string) => {
    try {
        const serializedItem = JSON.stringify(item);
        localStorage.setItem(key, serializedItem); 
    } catch (error) {
        console.log(error);
    }
}

export const clearStorage = () => {
    try {
        localStorage.clear();
    } catch (error) {
        console.log(error);
    }
}