import history from '../../History';
import {
    GOOGLE_SIGN_IN,
    GOOGLE_SIGN_OUT,
    API_SIGN_IN,
    API_SIGN_OUT,
    API_REGISTER_USER
} from './auth-types';
import api from '../../api/invoice.api';
import { RegisterFormValues } from '../../components/auth/RegisterForm';
import { saveItem } from '../../api/storage.api';
import { ProfileFormValues } from '../../components/pages/profile/ProfileForm';

const RESOURCE = 'auth';

const setToken = (response: any) => {
    const data = response.data;
    const token = getToken(data.cookie);
    saveItem(token, 'token');
    // api.defaults.headers.common.Authorization = `Bearer ${token}`;
    api.interceptors.request.use((config: any) => {
        if (config) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    });
}

const getToken = (cookie: string): string => {
    let splitToken = cookie.split('Authorization=');
    const token = splitToken[1].split('; ')[0];
    return token;
}

export const signIn = (userId: string) => {
    history.push('/dashboard');
    return {
        type: GOOGLE_SIGN_IN,
        payload: userId
    };
};

export const signOut = () => {
    history.push('');
    return {
        type: GOOGLE_SIGN_OUT
    };
};

export const apiSignIn = (formValues: any) => async (dispatch: Function) => {
    const response = await api.post(`${RESOURCE}/login`, { username: formValues.email, password: formValues.pass });
    if (response) {
        setToken(response);
        dispatch({
            type: API_SIGN_IN,
            payload: response.data
        });
        history.push('/dashboard');
    }
}

export const apiSignOut = () => {
    history.push('');
    return {
        type: API_SIGN_OUT
    };
};

export const apiRegisterUser = (registerForm: RegisterFormValues) => async (dispatch: Function) => {
    const response = await api.post(`${RESOURCE}/register`, registerForm);
    setToken(response);
    dispatch({
        type: API_REGISTER_USER,
        payload: response.data
    });
    history.push('/dashboard');
};

export const updateLoggedUser = (profileUser: ProfileFormValues) => {
    return {
        type: API_SIGN_IN,
        payload: { user: profileUser }
    };
}