import { 
  GOOGLE_SIGN_IN,
  GOOGLE_SIGN_OUT,
  API_SIGN_IN,
  API_SIGN_OUT,
  API_REGISTER_USER
 } from '../../actions/auth/auth-types';

const INITIAL_STATE = {
  isSignedIn: null,
  userId: null,
  loggedUser: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GOOGLE_SIGN_IN:
      return { ...state, isSignedIn: true, userId: action.payload };
    case GOOGLE_SIGN_OUT:
      return { ...state, isSignedIn: false, userId: null };
    case API_SIGN_IN:
      return { 
        ...state,
        isSignedIn: true,
        loggedUser: action.payload.user
      };
    case API_SIGN_OUT:
      return { isSignedIn: false, loggedUser: null };
    case API_REGISTER_USER:
      return { ...state, isSignedIn: true, loggedUser: action.payload.user };
    default:
      return state;
  }
};