import React from 'react';
import { Table, Form } from 'semantic-ui-react';
import { Field } from 'formik';
import { CurrencyDropdown } from '../../common/money/CurrencySelect';
import { TotalAmount } from '../../common/money/TotalAmount';

const handleIVAChange = (props: any) => {
    const values = props.form;
    const iva = values.iva;
    if (iva) {
        const ivaAmount = (iva * values.subTotal) / 100;
        props.setFieldValue('total', (values.subTotal + ivaAmount).toFixed(2));
    } else {
        props.setFieldValue('total', values.subTotal.toFixed(2));
    }
}

export const InvoiceTotalForm = (props: any) => {
    return (
        <Table definition>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Currency</Table.Cell>
                    <Table.Cell>
                        <CurrencyDropdown
                            name='currency'
                            onChangeCurrency={(currency: any) => {
                                props.setFieldValue('currency', currency);
                            }}
                            defaultValue={props.form.currency}
                        />
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Subtotal</Table.Cell>
                    <Table.Cell>
                        <Field
                            type='number'
                            name='subTotal'
                            icon='dollar sign'
                            iconPosition='left'
                            placeholder='US$ 0'
                            readOnly
                            as={Form.Input}
                            error={ props.errors?.subTotal && props.touched?.subTotal ? {
                                content: props.errors?.subTotal,
                                pointing: 'below'
                            } : null }
                        />
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>IVA</Table.Cell>
                    <Table.Cell>
                        <Field
                            type='number'
                            name='iva'
                            icon='percent'
                            iconPosition='left'
                            placeholder='0'
                            as={Form.Input}
                            error={ props.errors.iva && props.touched.iva ? {
                                content: props.errors.iva,
                                pointing: 'below'
                            } : null }
                            onBlur={(value: any) => handleIVAChange(props)}
                        />
                    </Table.Cell>
                </Table.Row>                                                        
                <Table.Row>
                    <Table.Cell>Total</Table.Cell>
                    <Table.Cell style={{textAlign:'center'}}>
                        <TotalAmount
                            currency={props.form.currency}
                            total={props.form.total}
                            size='big'
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>       
    );
};