import React, { Component } from "react";
import { Input, Image, Segment, Button } from "semantic-ui-react";

type Props = {
    imageURL?: string;
    showInput?: boolean;
    showPreview?: boolean;
    circular?: boolean;
    onSelectImage?: Function;
};

type State = {
    selectedFile: any;
    imageURL: any;
};

export class UploadImageInput extends Component<Props, State> {

    fileInput = React.createRef<any>();

    state = {
        selectedFile: null,
        imageURL: this.props.imageURL || 'https://react.semantic-ui.com/images/wireframe/square-image.png'
    };

    fileSelectedHandler = (event: any) => {
        this.setState({
            selectedFile: event.target.files[0],
            imageURL: URL.createObjectURL(event.target.files[0])
        });
        if (this.props.onSelectImage) {
            this.props.onSelectImage(event.target.files[0]);
        }
    }

    render() {
        const { imageURL } = this.state;
        return (
            <Segment>
                <Image
                    centered
                    src={imageURL}
                    size="medium"
                    circular={this.props.circular}
                />
                <br/><br/>
                <Input
                    id='fileInput'
                    onChange={this.fileSelectedHandler}
                    type='file'
                    style={{display: 'none'}}
                    ref={(input: any) => this.fileInput = input}
                />
                <Button
                    fluid
                    as='label'
                    htmlFor='fileInput'
                    onClick={() => this.fileInput.current?.click()}
                    content='Choose Image'
                    icon='file image'
                    labelPosition='left' />
            </Segment>
        );
    }
}
