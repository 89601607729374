export const months = [
    {
        key: '01',
        value: '01',
        text: 'Enero'
    },
    {
        key: '02',
        value: '02',
        text: 'Febrero'
    },
    {
        key: '03',
        value: '03',
        text: 'Marzo'
    },
    {
        key: '04',
        value: '04',
        text: 'Abril'
    },
    {
        key: '05',
        value: '05',
        text: 'Mayo'
    },
    {
        key: '06',
        value: '06',
        text: 'Junio'
    },
    {
        key: '07',
        value: '07',
        text: 'Julio'
    },
    {
        key: '08',
        value: '08',
        text: 'Agosto'
    },
    {
        key: '09',
        value: '09',
        text: 'Setiembre'
    },
    {
        key: '10',
        value: '10',
        text: 'Octubre'
    },
    {
        key: '11',
        value: '11',
        text: 'Noviembre'
    },
    {
        key: '12',
        value: '12',
        text: 'Diciembre'
    },
];

export const years = [
    { key: '2019', value: '2019', text: '2019' },
    { key: '2020', value: '2020', text: '2020' },
    { key: '2021', value: '2021', text: '2021' },
    { key: '2022', value: '2022', text: '2022' },
    { key: '2023', value: '2023', text: '2023' },
    { key: '2024', value: '2024', text: '2024' },
    { key: '2025', value: '2025', text: '2025' },
    { key: '2026', value: '2026', text: '2026' },
    { key: '2027', value: '2027', text: '2027' },
    { key: '2028', value: '2028', text: '2028' },
    { key: '2029', value: '2029', text: '2029' },
    { key: '2030', value: '2030', text: '2030' },
    { key: '2031', value: '2031', text: '2031' },
    { key: '2032', value: '2032', text: '2032' }
]