export const API_GET_RESOURCE = 'API_GET_RESOURCE';
export const API_GET_ALL_RESOURCE = 'API_GET_ALL_RESOURCE';
export const API_POST_RESOURCE = 'API_POST_RESOURCE';
export const API_PUT_RESOURCE = 'API_PUT_RESOURCE';
export const API_PATCH_RESOURCE = 'API_PATCH_RESOURCE';
export const API_DELETE_RESOURCE = 'API_DELETE_RESOURCE';

// Modal CRUD
export const OPEN_CREATE_EDIT_MODAL = 'OPEN_CREATE_EDIT_MODAL';
export const SET_RESOURCE_ID = 'SET_RESOURCE_ID';
export const SET_CURRENT_ENTITY = 'SET_CURRENT_ENTITY';
