import React, { Component } from 'react';
import { Form, Button, Checkbox, Grid } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import { clientFormSchema } from './validation';
import { RESOURCES } from '../../../actions/crud/resources';
import { UploadImageInput } from '../../custom/image/UploadImageInput';
import { ClientFormValues, Props } from './helper';
import { Toast } from '../../../utils/ToastifyFactory';

export class ClientForm extends Component<Props, any> {
	INITIAL_VALUES = {
		_id: undefined,
		name: '',
		surname: '',
		email: '',
		phone: '',
		celPhone: '',
		active: true,
		external: false,
        address: '',
        rut: ''
	} as ClientFormValues;

    state = {
        selectedImage: null,
        loading: false
    };

    onSelectImage = (imageFile: File) => {
        this.setState({
            selectedImage: imageFile
        });
    }

    uploadImage = async (id: string) => {
        try {
            if (this.state.selectedImage) {
                this.setState({ loading: true });
                if (this.props.onUploadImage) 
                    await this.props.onUploadImage(id, this.state.selectedImage);
            }
        } catch (error) {
            this.setState({ loading: false });
            console.log(error);
            Toast.error('An error occurred when trying to upload the image!');
        }
    }

	render() {
		const client = this.props.initialValues
			? this.props.initialValues.data
			: this.INITIAL_VALUES;
		return (
			<Formik
				initialValues={client}
				onSubmit={async (values, actions) => {
					if (client._id) {
						delete values._id;
						await this.props.onSubmitEdit(
							RESOURCES.clients,
							values,
							client._id
						);
                        await this.uploadImage(client._id);
					} else {
                        values.image = this.state.selectedImage;
						await this.props.onSubmit(RESOURCES.clients, values);
					}
                    Toast.success('Client saved successfully!');
					actions.resetForm();
					await this.props.onClose();
				}}
				validationSchema={clientFormSchema}
			>
				{({
					values,
					errors,
					touched,
					handleSubmit,
					resetForm,
					isSubmitting,
					setFieldValue,
					getFieldProps,
				}) => (
					<Form
						size="large"
						className="ui error"
						onSubmit={handleSubmit}
						loading={isSubmitting}
					>
						<Grid divided>
							<Grid.Row columns={2}>
								<Grid.Column width={6}>
									<UploadImageInput 
										circular={true}
										onSelectImage={this.onSelectImage}
										imageURL={client.imageURL}
									/>
								</Grid.Column>
								<Grid.Column width={10}>
									<Field
										name="name"
										fluid
										icon="user"
										iconPosition="left"
										placeholder="Name"
										as={Form.Input}
										error={
											errors.name && touched.name
												? {
														content: errors.name,
														pointing: 'below',
												  }
												: null
										}
									/>
									<Field
										name="surname"
										fluid
										icon="user"
										iconPosition="left"
										placeholder="Surname"
										as={Form.Input}
										error={
											errors.surname && touched.surname
												? {
														content: errors.surname,
														pointing: 'below',
												  }
												: null
										}
									/>
									<Field
										name="email"
										fluid
										icon="envelope"
										iconPosition="left"
										placeholder="E-mail address"
										as={Form.Input}
										error={
											errors.email && touched.email
												? {
														content: errors.email,
														pointing: 'below',
												  }
												: null
										}
									/>
									<Field
										name="phone"
										fluid
										icon="phone"
										iconPosition="left"
										placeholder="Phone Number"
										as={Form.Input}
										error={
											errors.phone && touched.phone
												? {
														content: errors.phone,
														pointing: 'below',
												  }
												: null
										}
									/>
									<Field
										name="celPhone"
										fluid
										icon="mobile alternate"
										iconPosition="left"
										placeholder="Cel Phone Number"
										as={Form.Input}
										error={
											errors.celPhone && touched.celPhone
												? {
														content:
															errors.celPhone,
														pointing: 'below',
												  }
												: null
										}
									/>
									<Field
										name="rut"
										fluid
										icon="id card"
										iconPosition="left"
										placeholder="RUT Number"
										as={Form.Input}
										error={
											errors.rut && touched.rut
												? {
														content: errors.rut,
														pointing: 'below',
												  }
												: null
										}
									/>
									<Field
										name="address"
										fluid
										icon="map signs"
										iconPosition="left"
										placeholder="Address"
										as={Form.Input}
										error={
											errors.address && touched.address
												? {
														content: errors.address,
														pointing: 'below',
												  }
												: null
										}
									/>
									<Field
										name="external"
										label="Client is External?"
										component={Checkbox}
										checked={values.external}
										onChange={() =>
											setFieldValue(
												'external',
												!values.external
											)
										}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<br />
						<br />
						<Button.Group style={{ float: 'right' }}>
							<Button
								color="grey"
								type="reset"
								onClick={() => {
									resetForm();
									this.props.onClose();
								}}
							>Close</Button>
							<Button.Or />
							<Button type="submit" positive>Save</Button>
						</Button.Group>
					</Form>
				)}
			</Formik>
		);
	}
}
