import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { PDFDocumentProxy } from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};


export const PDFViewer = (props: any) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document
        file={props.url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        options={options}
      >
        <Page
            width={props.width || 500}
            height={props.height || 500}
            pageNumber={pageNumber}
          />
      </Document>
      <p style={{display: "none"}}>Page {pageNumber} of {numPages}</p>
    </div>
  );
}