import React from 'react';
import { Button, Icon, Image, Label, Modal } from 'semantic-ui-react';
import { isImageURL } from '../../../utils/FileUtils';
import { PDFViewer } from './PDFViewer';

export const ImageModal = (props: any) => {
	const [open, setOpen] = React.useState(false);

	return (
		<Modal
			dimmer="blurring"
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			trigger={
				<Button as="div" labelPosition="right" type="button" fluid>
					<Button color="green" type="button" fluid>
						<Icon name="image" /> Show Image
					</Button>
					<Label basic color="green" pointing="left">
						{props.filename}
					</Label>
				</Button>
			}
		>
			<Modal.Header>Upload image</Modal.Header>
			<Modal.Content>
				{isImageURL(props.url) ? (
					<Image
						centered
						size="big"
						src={
							props.url ||
							'https://react.semantic-ui.com/images/wireframe/image-square.png'
						}
					/>
				) : (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<PDFViewer url={props.url} />
					</div>
				)}
			</Modal.Content>
		</Modal>
	);
};
