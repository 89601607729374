import { format, isAfter } from 'date-fns';

export const parseStartDate = (date: Date): string => {
    const result = date;
    result.setHours(0, 0, 0);
    return format(result, 'yyyy-MM-dd HH:mm:ss')
}

export const parseEndDate = (date: Date): string => {
    const result = date;
    result.setHours(23, 59, 59);
    return format(result, 'yyyy-MM-dd HH:mm:ss')
}

export const isExpired = (date: string): boolean => {
    const d = new Date(date);
    return isAfter(new Date(), d)
}