import React, { Component } from "react";
import { Input, Image, Segment, Button } from "semantic-ui-react";
import { isImageFile, isImageURL, isPDFURL } from '../../../utils/FileUtils';
import { PDFViewer } from './PDFViewer';

type Props = {
    url?: string;
    showInput?: boolean;
    showPreview?: boolean;
    circular?: boolean;
    onSelect?: Function;
};

type State = {
    selectedFile: any;
    url: any;
};

export class UploadImagePDFInput extends Component<Props, State> {

    fileInput = React.createRef<any>();

    state = {
        selectedFile: null,
        url: this.props.url || 'https://react.semantic-ui.com/images/wireframe/square-image.png'
    };

    fileSelectedHandler = (event: any) => {
        this.setState({
            selectedFile: event.target.files[0],
            url: URL.createObjectURL(event.target.files[0])
        });
        if (this.props.onSelect) {
            this.props.onSelect(event.target.files[0]);
        }
    }

    render() {
        const { url, selectedFile } = this.state;
        let renderViewer = null;
        if (isPDFURL(url) || (!isImageURL(url) && !isImageFile(selectedFile))) {
            renderViewer = (
                <PDFViewer url={selectedFile || url}/>
            );
        } else {
            renderViewer = (
                <Image
                    centered
                    src={url}
                    size="medium"
                    circular={this.props.circular}
                />
            );
        }
        return (
            <Segment>
                { renderViewer }
                <br/><br/>
                <Input
                    id='fileInput'
                    onChange={this.fileSelectedHandler}
                    type='file'
                    style={{display: 'none'}}
                    ref={(input: any) => this.fileInput = input}
                />
                <Button
                    fluid
                    as='label'
                    htmlFor='fileInput'
                    onClick={() => this.fileInput.current?.click()}
                    content='Choose Image'
                    icon='file image'
                    labelPosition='left' />
            </Segment>
        );
    }
}
