import {
	DASH_GET_BAR_CHART,
	DASH_GET_LAST_INFO,
	DASH_GET_LINE_CHART,
	DASH_GET_PIE_CHART,
	DASH_GET_STATISTICS,
} from '../../actions/dashboard/dashboard.types';

const INITIAL_STATE = {
	statistics: [],
	expensesChart: [],
	invoicesChart: [],
	lastInfo: [],
};

export default function DashboarReducer(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case DASH_GET_STATISTICS:
			return { ...state, statistics: action.payload };
		case DASH_GET_PIE_CHART:
			return { ...state, expensesChart: action.payload };
		case DASH_GET_LINE_CHART:
			return { ...state, invoicesChart: action.payload };
		case DASH_GET_BAR_CHART:
			return { ...state, barChart: action.payload };
		case DASH_GET_LAST_INFO:
			return { ...state, lastInfo: action.payload };
		default:
			return state;
	}
}
