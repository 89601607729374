import React from 'react';
import { Field } from 'formik';
import { Form, Grid } from 'semantic-ui-react';
import { CurrencyDropdown } from '../../common/money/CurrencySelect';
import { TotalAmount } from '../../common/money/TotalAmount';

const handleIVAChange = (props: any) => {
	const values = props.form;
	const iva = values.iva;
	if (iva) {
		const ivaAmount = (iva * values.subTotal) / 100;
		props.setFieldValue('total', values.subTotal + ivaAmount);
	} else {
		props.setFieldValue('total', values.subTotal);
	}
};

export const ExpenseTotalForm = (props: any) => {
	return (
		<Grid>
			<Grid.Row>
				<Grid.Column>
					<CurrencyDropdown
						name="currency"
						onChangeCurrency={(currency: any) => {
							props.setFieldValue('currency', currency);
						}}
						defaultValue={props.form.currency}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={2}>
				<Grid.Column>
					<Field
						type="number"
						name="subTotal"
						icon="dollar sign"
						iconPosition="left"
						placeholder="US$ 0"
						as={Form.Input}
						error={
							props.errors?.subTotal && props.touched?.subTotal ? {
                                content: props.errors?.subTotal
                            } : null
						}
                        onBlur={(value: any) => handleIVAChange(props)}
					/>
				</Grid.Column>
				<Grid.Column>
					<Field
						type="number"
						name="iva"
						icon="percent"
						iconPosition="left"
						placeholder="0"
						as={Form.Input}
						error={
							props.errors.iva && props.touched.iva ? {
                                content: props.errors.iva
                            } : null
						}
						onBlur={(value: any) => handleIVAChange(props)}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={2}>
				<Grid.Column>
					<Field
						type="number"
						name="discount"
						label="Discount"
						icon="dollar sign"
						iconPosition="left"
						placeholder="$ 1000"
						as={Form.Input}
						error={
							props.errors?.discount && props.touched?.discount ? {
								content: props.errors?.discount
							} : null
						}
					/>
				</Grid.Column>
				<Grid.Column  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<TotalAmount
						style={{disply: 'flex', alignItems: 'center'}}
						currency={props.form.currency}
						total={props.form.total}
						size="big"
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};
