import React from 'react';
import { Icon, Pagination } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

export const InvoicePagination = (props: any) => {
    console.log(props);
    const totalPages = isEmpty(props.invoices) ? 0 :
        Math.ceil(props?.invoices?.total / props?.invoices?.limit);
    return (
        <Pagination
            floated='right'
            onPageChange={props?.handlePageChange}
            defaultActivePage={1}
            ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
            }}
            firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
            }}
            lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
            }}
            prevItem={{
                content: <Icon name="angle left" />,
                icon: true,
            }}
            nextItem={{
                content: <Icon name="angle right" />,
                icon: true,
            }}
            totalPages={totalPages}
        />        
    );
}