import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Segment, Statistic } from 'semantic-ui-react';
import { getStatistics } from '../../../actions/dashboard/dashboard.actions';

type Props = {
    getStatistics: Function,
    statistics: any
};
type State = {}

class DashboardStatisticsHeader extends Component<Props, State> {

    async componentDidMount() {
        await this.props.getStatistics();
    }

	render() {
		const style = { backgroundColor: '#f5f5f5' };
		return (
			<Segment color="green" style={style}>
				<Statistic.Group widths="four">
					<Statistic color='green'>
						<Statistic.Value>
                            <Icon size='small' name='currency' />&nbsp;
                            {this.props.statistics?.invoices}
                        </Statistic.Value>
						<Statistic.Label>Invoices</Statistic.Label>
					</Statistic>

					<Statistic color='red'>
						<Statistic.Value>
                            <Icon size='small' name='dollar' />&nbsp;
                            {this.props.statistics?.expenses}
                        </Statistic.Value>
						<Statistic.Label>Expenses</Statistic.Label>
					</Statistic>

					<Statistic color='teal'>
						<Statistic.Value>
							<Icon size='small' name="user" />&nbsp;
                            {this.props.statistics?.clients}
						</Statistic.Value>
						<Statistic.Label>Clients</Statistic.Label>
					</Statistic>

					<Statistic color='blue'>
						<Statistic.Value>
							<Icon size='small' name='dollar sign' />
							{this.props.statistics?.lastInvoice}
						</Statistic.Value>
						<Statistic.Label>Last Invoice</Statistic.Label>
					</Statistic>
				</Statistic.Group>
			</Segment>
		);
	}
}

const mapStateToProps = (state: any) => {
    return {
        statistics: state.entities.dashboard.statistics
    };
};

export default connect(mapStateToProps, {
    getStatistics
})(DashboardStatisticsHeader);


