import React, { Component } from 'react';
import { Button, Input, Segment } from 'semantic-ui-react';
import { ImageModal } from './ImageModal';

export class FileInput extends Component<any, any> {
	fileInput = React.createRef<any>();

	state = {
        selectedFile: null,
        filename: '',
		url: this.props.url,
	};

	fileSelectedHandler = (event: any) => {
		this.setState({
            selectedFile: event.target.files[0],
            filename: event.target.files[0].name,
			url: URL.createObjectURL(event.target.files[0]),
		});
		if (this.props.onSelectImage) {
			this.props.onSelectImage(event.target.files[0]);
		}
    }
    
    getFileName = (): string => {
        if (this.props.url) {
            const filename = this.props.url.split('invoices')[1];
            return filename.split('/')[1];
        }
        return '';
    }

	render() {
		const { url, filename } = this.state;
		return (
			<Segment>
				<Input
                    id='fileInput'
                    onChange={this.fileSelectedHandler}
                    type='file'
                    style={{display: 'none'}}
                    ref={(input: any) => this.fileInput = input}
                />
                <Button
                    fluid
                    as='label'
                    htmlFor='fileInput'
                    onClick={() => this.fileInput.current?.click()}
                    content='Choose Image'
                    icon='file image'
                    labelPosition='left' />
                    <br/>
                {
                    this.state.selectedFile || url ?
                        <ImageModal url={url} filename={filename || this.getFileName()}/> : null
                }
			</Segment>
		);
	}
}
