import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

export class CustomDateInput extends Component<any, any> {
    render() {
        return (<Form.Input
            label={this.props.label || null}
            style={this.props.style}
            value={this.props.value}
            onClick={this.props.onClick}
            icon='calendar'
            iconPosition='left'
            placeholder='Date of born...'
            error={this.props.error ? {
                content: this.props.error
            }: null }/>
        )
    }
}