import {
	API_GET_ALL_RESOURCE,
	API_GET_RESOURCE,
	SET_CURRENT_ENTITY,
	API_POST_RESOURCE,
	API_PUT_RESOURCE,
	API_DELETE_RESOURCE,
} from '../../actions/crud/crud.types';
import { RESOURCES } from '../../actions/crud/resources';
import { filter } from 'lodash';

const INITIAL_STATE = {
	invoices: [],
	currentId: null,
	currentInvoice: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case `${API_GET_ALL_RESOURCE}_${RESOURCES.invoices.toLocaleUpperCase()}`:
			return { ...state, invoices: action.payload };
		case `${API_GET_RESOURCE}_${RESOURCES.invoices.toLocaleUpperCase()}`:
			return { ...state, currentInvoice: action.payload };
		case `${API_POST_RESOURCE}_${RESOURCES.invoices.toLocaleUpperCase()}`:
			return { ...state, currentInvoice: action.payload };
		case `${API_PUT_RESOURCE}_${RESOURCES.invoices.toLocaleUpperCase()}`:
			return { ...state, currentInvoice: action.payload };
		case `${SET_CURRENT_ENTITY}_${RESOURCES.invoices.toLocaleUpperCase()}`:
			return { ...state, currentInvoice: action.payload };
		case `${API_DELETE_RESOURCE}_${RESOURCES.invoices.toLocaleUpperCase()}`:
			const invoices = filter(
				state.invoices?.values,
				(invoice: any) => invoice._id !== action.payload
			);
			return {
				...state,
				invoices: {
					...state.invoices,
					values: invoices
				}
			};
		default:
			return state;
	}
};
