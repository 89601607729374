import React, { Component } from 'react';
import { Placeholder } from 'semantic-ui-react';

export class PlaceholderLoading extends Component {
    render() {
        return (
            <Placeholder fluid>
                <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder.Paragraph>
            </Placeholder>
        );
    }
}
