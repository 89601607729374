import api from '../../api/invoice.api';
import { RESOURCES } from '../crud/resources';
import {
	DASH_GET_BAR_CHART,
	DASH_GET_LAST_INFO,
	DASH_GET_LINE_CHART,
	DASH_GET_PIE_CHART,
	DASH_GET_STATISTICS,
} from './dashboard.types';

export const getStatistics = () => async (dispatch: Function) => {
	const response = await api.get(`${RESOURCES.dashboard}/statistics`) as any;
	dispatch({
		type: DASH_GET_STATISTICS,
		payload: response?.data?.data,
	});
};

export const getPieChartData = () => async (dispatch: Function) => {
	const response = await api.get(`${RESOURCES.dashboard}/pie-chart`) as any;
	dispatch({
		type: DASH_GET_PIE_CHART,
		payload: response?.data?.data,
	});
};

export const getLineChartData = () => async (dispatch: Function) => {
	const response = await api.get(`${RESOURCES.dashboard}/line-chart`) as any;
	dispatch({
		type: DASH_GET_LINE_CHART,
		payload: response?.data?.data,
	});
};

export const getBarChartData = () => async (dispatch: Function) => {
	const response = await api.get(`${RESOURCES.dashboard}/bar-chart`) as any;
	dispatch({
		type: DASH_GET_BAR_CHART,
		payload: response?.data?.data,
	});
};

export const getLastInfo = () => async (dispatch: Function) => {
	const response = await api.get(`${RESOURCES.dashboard}/last-info`) as any;
	dispatch({
		type: DASH_GET_LAST_INFO,
		payload: response?.data?.data,
	});
};
