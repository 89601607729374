import { API_GET_ALL_RESOURCE } from '../../actions/crud/crud.types';
import { RESOURCES } from '../../actions/crud/resources';

const INITIAL_STATE = {
   categories: [] 
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case `${API_GET_ALL_RESOURCE}_${RESOURCES.categories.toLocaleUpperCase()}`:
            return { ...state, categories: action.payload };                           
        default:
            return state;
    }
};