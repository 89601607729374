import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { AppRouter } from "../../router/Router";

export class PageContainer extends Component {
    render() {
        return (
            <Segment className='page-container-segment' basic>
                <AppRouter />
            </Segment>
        );
    }
}
