import React, { Component } from 'react';
import { Button, Card, Grid, Icon, Segment } from 'semantic-ui-react';
import { UploadImageInput } from '../../custom/image/UploadImageInput';
import { ProfileForm } from './ProfileForm';
import { connect } from 'react-redux';
import { getProfile, updateProfile } from '../../../actions/user/user.actions';
import { PlaceholderLoading } from '../../common/placeholder/PlaceholderLoading';
import { updateLoggedUser } from '../../../actions/auth/auth-actions';
import { uploadProfileImage } from '../../../actions/user/user.actions';
import { Toast } from '../../../utils/ToastifyFactory';

type State = {
    selectedImage?: File | null,
    loading: boolean
}

class ProfilePage extends Component<any, State> {
    
    state = {
        selectedImage: null,
        loading: false
    }

	async componentDidMount() {
		await this.props.getProfile();
	}

	onSelectImage = (imageFile: File) => {
        this.setState({
            selectedImage: imageFile
        });
    }

	uploadImage = async () => {
		try {
            if (this.state.selectedImage) {
				this.setState({ loading: true });
                await uploadProfileImage(this.state.selectedImage);
                this.setState({ loading: false });
                Toast.success('Image saved successfully!');
            }
		} catch (error) {
            this.setState({ loading: false });
			Toast.error('An error occurred when trying to upload the image!');
		}
	};

	render() {
		const style = { backgroundColor: '#f5f5f5' };
        const { loading, selectedImage } = this.state;
		if (!this.props.loggedUser) {
			return (
				<div>
					<Segment color='green' style={style}>
						<h2>Profile Information</h2>
					</Segment>
					<Segment color='green' style={style}>
						<PlaceholderLoading />
					</Segment>
				</div>
			);
		}
		return (
			<div>
				<Segment color='green' style={style}>
					<h2>Profile Information</h2>
				</Segment>
				<Segment color='green' style={style}>
					<Grid>
						<Grid.Column computer={4} tablet={5} mobile={16}>
                            <UploadImageInput 
                                circular={true}
                                onSelectImage={this.onSelectImage}
                                imageURL={this.props.loggedUser.data.imageURL}
                            />
                            <Button
                                disabled={!selectedImage}
                                loading={loading}
                                onClick={this.uploadImage} 
                                icon
                                labelPosition="left"
                                fluid>
								<Icon name="cloud upload" /> Upload
							</Button>
						</Grid.Column>
						<Grid.Column computer={12} tablet={11} mobile={16}>
							<Card fluid>
								<ProfileForm
									updateLoggedUser={
										this.props.updateLoggedUser
									}
									onSubmit={this.props.updateProfile}
									initialValues={this.props.loggedUser}
								/>
							</Card>
						</Grid.Column>
					</Grid>
				</Segment>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		loggedUser: state.user.loggedUser,
	};
};

export default connect(mapStateToProps, {
	getProfile,
	updateProfile,
	updateLoggedUser,
})(ProfilePage);
