import React, { Component } from 'react';
import _ from 'lodash';
import { Search } from 'semantic-ui-react';

const initialState = { isLoading: false, results: [], value: '' };

export default class SearchInput extends Component<any, any> {
	state = {
		...initialState,
		value: this.props.selected ? this.props.selected.fullName : '',
		source: _.map(this.props.values, (value) => {
			return {
				id: value._id,
				title: value.name + ' ' + value.surname,
				image: 'https://picsum.photos/300?random',
			}
		})
	};

	handleResultSelect = (e: any, result: any) => {
		const title = result.result.title;
		this.setState({ value: title });
		if (this.props.setFieldValue)
			this.props.handleResultSelect(result.result, this.props.setFieldValue);
		else
			this.props.handleResultSelect(result.result);	
    }

	handleSearchChange = (e: any, value: any) => {
		this.setState({ isLoading: true, value: value.value });
		setTimeout(() => {
			if (this.state.value.length < 1) return this.setState(initialState);

			const re = new RegExp(_.escapeRegExp(this.state.value), 'i');
			const isMatch = (result: any) => re.test(result.title);			
			this.setState({
				isLoading: false,
				results: _.filter(this.state.source, isMatch),
			});
		}, 300);
	};

	render() {
		const { isLoading, results } = this.state;
		let value = this.state.value;
		if (this.props.selected === null) value = '' 
		return (
			<Search
				loading={isLoading}
				onResultSelect={this.handleResultSelect}
				onSearchChange={_.debounce(this.handleSearchChange, 500, {
					leading: true,
				})}
				results={results}
				value={value}
				input={this.props.input}
			/>
		);
	}
}
