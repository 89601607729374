import React, { Component } from 'react';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';
import {
	SemanticCOLORS,
	SemanticICONS,
} from 'semantic-ui-react/dist/commonjs/generic';

type Props = {
	id?: string;
	message: string;
	onConfirm: Function;
	title: string;
	triggerTitle: string;
	triggerButton?: any;
	titleIcon?: SemanticICONS;
	isDelete?: boolean;
	isIcon?: boolean;
	floated?: 'right' | 'left'
};

export default class ConfirmModal extends Component<Props, any> {
	state = { modalOpen: false };

	handleOpen = () => this.setState({ modalOpen: true });

	handleClose = () => this.setState({ modalOpen: false });

	handleConfirm = () => {
		this.props.onConfirm(this.props.id);
		this.handleClose();
	};

	render() {
		let color: SemanticCOLORS = 'green';
		if (this.props.isDelete) {
			color = 'red';
		}
		const modalTrigger = this.props.isIcon ? (
			<Icon
				color={color}
                name={this.props.titleIcon}
                onClick={this.handleOpen}
			></Icon>
		) : (
			<Button
				inverted
				color={color}
				onClick={this.handleOpen}
				floated={this.props.floated}>
				{this.props.triggerTitle}
			</Button>
		);
		return (
			<Modal
				dimmer="blurring"
				trigger={modalTrigger}
				open={this.state.modalOpen}
				onClose={this.handleClose}
				closeIcon
			>
				<Header
					icon={this.props.titleIcon}
					content={this.props.title}
				/>
				<Modal.Content>
					<p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
						{this.props.message}
					</p>
				</Modal.Content>

				<Modal.Actions>
					<Button.Group
						style={{ float: 'right', paddingBottom: '15px' }}
					>
						<Button color="grey" onClick={this.handleClose}>
							Cancel
						</Button>
						<Button.Or />
						<Button onClick={this.handleConfirm} color={color}>
							Confirm
						</Button>
					</Button.Group>
				</Modal.Actions>
			</Modal>
		);
	}
}
