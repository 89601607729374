import React from 'react';
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from '@nivo/pie';
import { Message } from 'semantic-ui-react';

export const DashboardPieChart = (props: any) => {
	if (props?.data?.length === 0 || props?.data?.length <= 1) {
		return (
			<Message info>
				<Message.Header>There are no expenses to show!</Message.Header>
				<p>You can add expenses with the button located on the menu</p>
			</Message>
		);
	}
	return (
		<div style={{ height: '350px' }}>
			<ResponsivePie
				data={props.data || []}
				margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
				innerRadius={0.5}
				padAngle={0.7}
				cornerRadius={3}
				colors={{ scheme: 'nivo' }}
				borderWidth={1}
				borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
				legends={[
					{
						anchor: 'bottom',
						direction: 'row',
						justify: false,
						translateX: 0,
						translateY: 56,
						itemsSpacing: 0,
						itemWidth: 100,
						itemHeight: 18,
						itemTextColor: '#999',
						itemDirection: 'left-to-right',
						itemOpacity: 1,
						symbolSize: 18,
						symbolShape: 'circle',
						effects: [
							{
								on: 'hover',
								style: {
									itemTextColor: '#000',
								},
							},
						],
					},
				]}
			/>
		</div>
	);
};
