import React, { SyntheticEvent } from 'react';
import { Dropdown } from 'semantic-ui-react';

export const currencies = [
    {
        name: 'Pesos Uruguay',
        symbol: '$',
        shortName: 'uy'
    },
    {
        name: 'Dolares USA',
        symbol: 'US$',
        shortName: 'us'
    }
];

export const currenciesOptions = [
    { key: 'uy', value: 'uy', flag: 'uy', text: 'Pesos Uruguay' },
    { key: 'us', value: 'us', flag: 'us', text: 'Dolares USA' }
];

const mapToCurrency = (value: string) => {
    return currencies.filter(e => e.shortName === value).pop();
}

export const CurrencyDropdown = (props: any) => {
    return (
        <Dropdown
            name={props.name}
            defaultValue={props.defaultValue?.shortName || 'us'}
            onChange={(event: SyntheticEvent, data: any) => {
                props.onChangeCurrency(mapToCurrency(data.value));
            }}
            placeholder='Select Currency'
            fluid
            selection
            options={currenciesOptions}
        />
    );
}