import * as Yup from 'yup';

export const loginFormSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid Email!')
        .required('Required!'),
    pass: Yup.string()
        .min(6, 'Password Too Short!')
        .max(15, 'Password Too Long!')
        .required('Required!')
});

export const registerFormSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Username Too Short!')
        .max(50, 'Username Too Long!')
        .required('Required!'),
    name: Yup.string()
        .min(3, 'Name Too Short!')
        .max(50, 'Name Too Long!')
        .required('Required!'),
    surname: Yup.string()
        .min(3, 'Surname Too Short!')
        .max(50, 'Surname Too Long!')
        .required('Required!'),
    email: Yup.string()
        .email('Invalid Email!')
        .required('Required!'),
    ci: Yup.string()
        .min(8, 'CI Too Short!')
        .max(8, 'CI Too Long!'),
    password: Yup.string()
        .min(6, 'Password Too Short!')
        .max(15, 'Password Too Long!')
        .required('Required!'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Required!'),
    dob: Yup.date(),
    active: Yup.bool()
});