import * as Yup from 'yup';

export const profileFormSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Username Too Short!')
        .max(50, 'Username Too Long!')
        .required('Required!'),
    name: Yup.string()
        .min(3, 'Name Too Short!')
        .max(50, 'Name Too Long!')
        .required('Required!'),
    surname: Yup.string()
        .min(3, 'Surname Too Short!')
        .max(50, 'Surname Too Long!')
        .required('Required!'),
    email: Yup.string()
        .min(1, 'Email Too Short!')
        .max(35, 'Email Too Long!')
        .email('Invalid Email!')
        .required('Required!'),
    ci: Yup.string()
        .min(8, 'CI Too Short!')
        .max(8, 'CI Too Long!'),
    rut: Yup.string()
        .min(8, 'RUT Too Short!')
        .max(12, 'RUT Too Long!'),        
    dob: Yup.date(),
    active: Yup.bool()
});