import React, { Component } from 'react';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';
import { ClientForm } from './ClientForm';
import { connect } from 'react-redux';
import {
    getResource,
    getAllResources,
    postResource,
    putResource,
    openModal,
    setCurrentEntity,
    uploadImage
} from '../../../actions/crud/crud.actions';
import { RESOURCES } from '../../../actions/crud/resources';

class ClientModal extends Component<any, any> {

    handleOpen = () => {
        this.props.openModal(RESOURCES.clients, !this.props.isOpenModal);
        this.props.setCurrentEntity(RESOURCES.clients, null);
    }

    handleClose = () => {
        this.props.getAllResources(RESOURCES.clients);
        this.props.openModal(RESOURCES.clients, !this.props.isOpenModal);
        this.props.setCurrentEntity(RESOURCES.clients, null);
    }

    onUploadImage = async (id: string, file: any) => {
        await uploadImage(id, RESOURCES.clients, file);
    }

    render() {
        const style = { flex: 'auto' };
        const open = this.props.isOpenModal;
        const trigger = (
            <Button
                onClick={this.handleOpen} 
                icon
                color='green'
                labelPosition='left'
                floated='right'>
                <Icon name='plus square outline'/> Add Client
            </Button>
        );
        const initialValues = this.props.currentClient;
        return (
            <Modal
                open={open}
                trigger={trigger}
                size="large"
                onClose={this.handleOpen}
                closeIcon
            >
                <Header content='Save a Client' icon='pencil alternate'/>
                <Modal.Content image>
                    <Modal.Description style={style}>
                        <Header>Client Information</Header>
                        <ClientForm 
                            initialValues={initialValues}
                            onSubmit={this.props.postResource}
                            onSubmitEdit={this.props.putResource}
                            onClose={this.handleClose}
                            onUploadImage={this.onUploadImage}
                        />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isOpenModal: state.entities.client.isOpenModal,
        currentClient: state.entities.client.currentClient,
        currentId: state.entities.client.currentId
    };
};

export default connect(mapStateToProps, {
    getAllResources,
    getResource,
    postResource,
    putResource,
    openModal,
    setCurrentEntity
})(ClientModal);
