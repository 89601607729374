import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { apiSignOut } from '../actions/auth/auth-actions';
import store from '../index';
import { Toast } from '../utils/ToastifyFactory';
import { stringify } from 'qs'


const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    paramsSerializer: {
        serialize: (params: any) => stringify(params, {arrayFormat: 'brackets'})
    }
});
api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = JSON.parse(localStorage.getItem('token') as string);
    if (token) {
        if (config) {
            config.headers = { Authorization: `Bearer ${token}` } as any;
        }
    }
    return config;
});
api.interceptors.response.use((next: AxiosResponse<any>) => {
    return Promise.resolve(next);
}, (error: any) => {
    if (error.response?.status === 401) {
        store.dispatch(apiSignOut());
    } else {
        const msg = error.response?.data?.message;
        Toast.error(msg);
    }
});

export default api;