import React, { useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import SearchInput from '../../custom/search/SearchInput';
import DatePicker from 'react-datepicker';

export const InvoiceFilter = (props: any) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [searchText, setSearchText] = useState('');
    return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex'}}>
                <SearchInput
                    selected={searchText}
                    values={props.clients}
                    handleResultSelect={(data: any) => {
                        setSearchText(data.title);
                        props.handleClientSelect(data);
                    }}
                    input={
                        <Input
                            style={{ width: '300px', marginRight: '10px' }}
                            icon='user secret'
                            iconPosition='left'
                            placeholder='Search Client...'
                        />
                    }
                />
                <DatePicker
                    name="startDate"
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    onChange={(date: any) => {
                        console.log(date);
                        setStartDate(date);
                        props.handleStartDateChange(date);
                    } }
                    placeholderText='From Date'
                    customInput={
                        <Input
                            style={{marginRight: '10px'}}
                            icon='calendar'
                            iconPosition='left'
                        />
                    }
                />
                <DatePicker
                    name="endDate"
                    selected={endDate}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    onChange={(date: any) => {
                        console.log(date);
                        setEndDate(date);
                        props.handleEndDateChange(date);
                    } }
                    placeholderText='To Date'
                    customInput={
                        <Input
                            style={{marginRight: '10px'}}
                            icon='calendar'
                            iconPosition='left'
                        />
                    }
                />            
                <Button circular icon='refresh' onClick={() => {
                    setSearchText('');
                    setStartDate(undefined);
                    setEndDate(undefined);
                    props.handleRefresh();
                }}/>
            </div>
            {props.children ?
            <div>
                {props.children}
            </div> 
            : null}
        </div>
    );
}