import {
	API_GET_ALL_RESOURCE,
	API_GET_RESOURCE,
	SET_CURRENT_ENTITY,
	API_POST_RESOURCE,
	API_PUT_RESOURCE,
	API_DELETE_RESOURCE,
	OPEN_CREATE_EDIT_MODAL,
} from '../../actions/crud/crud.types';
import { RESOURCES } from '../../actions/crud/resources';
import { filter } from 'lodash';
import { GET_EXPENSES_BY_MONTH, RESET_EXPENSES_BY_MONTH } from '../../actions/expense/expense.types';

const INITIAL_STATE = {
	expenses: [],
	currentId: null,
	currentExpense: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case `${API_GET_ALL_RESOURCE}_${RESOURCES.expenses.toLocaleUpperCase()}`:
			return { ...state, expenses: action.payload };
		case `${API_GET_RESOURCE}_${RESOURCES.expenses.toLocaleUpperCase()}`:
			return { ...state, currentExpense: action.payload };
		case `${API_POST_RESOURCE}_${RESOURCES.expenses.toLocaleUpperCase()}`:
			return { ...state, currentExpense: action.payload };
		case `${API_PUT_RESOURCE}_${RESOURCES.expenses.toLocaleUpperCase()}`:
			return { ...state, currentExpense: action.payload };
		case `${SET_CURRENT_ENTITY}_${RESOURCES.expenses.toLocaleUpperCase()}`:
			return { ...state, currentExpense: action.payload };
		case `${API_DELETE_RESOURCE}_${RESOURCES.expenses.toLocaleUpperCase()}`:
			const expenses = filter(
				state.expenses?.values,
				(expense: any) => expense._id !== action.payload
			);
			return {
				...state,
				expenses: {
					...state.expenses,
					values: expenses
				}
			};
		case `${OPEN_CREATE_EDIT_MODAL}_${RESOURCES.expenses.toLocaleUpperCase()}`:
			return { ...state, isOpenModal: action.payload };
		case GET_EXPENSES_BY_MONTH:
			return  { ...state, expensesByMonth: action.payload }
		case RESET_EXPENSES_BY_MONTH:
			return  { ...state, expensesByMonth: [] }
		default:
			return state;
	}
};
