import React from 'react';
import { Table, Icon, Message } from 'semantic-ui-react';
import ExpenseModal from './ExpenseModal';
import ConfirmModal from '../../common/modal/ConfirmModal';
import { format } from 'date-fns';
import _ from 'lodash';
import { TotalAmount } from '../../common/money/TotalAmount';
import { ExpensePagination } from './ExpensePagination';
import { isExpired } from '../../../utils/DateUtil';

export const ExpenseTable = (props: any) => {
	const expenses = props.expenses?.values;
	if (_.isEmpty(expenses) || _.isEmpty(props.expenses.values)) {
		return (
			<div>
				<Message info>
					<Message.Header>There are no expenses to show!</Message.Header>
					<p>You can add expenses with the button located on the top right</p>
				</Message>
				<ExpenseModal categories={props.categories} onClose={props.handleClose}/>
			</div>
		);
	}
	return (
		<Table compact celled definition selectable>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell />
					<Table.HeaderCell>Date</Table.HeaderCell>
					<Table.HeaderCell>Category</Table.HeaderCell>
					<Table.HeaderCell>Notes</Table.HeaderCell>
					<Table.HeaderCell style={{ textAlign: 'center' }}>
						Total Amount
					</Table.HeaderCell>
					<Table.HeaderCell></Table.HeaderCell>
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{expenses
					? expenses.map((expense: any, index: number) => {
							const discount = expense.discount || 0;
							const total = expense.total - discount;
							return (
								<Table.Row key={index} 
									negative={isExpired(expense.dueDate) && !expense.isPaid}
									warning={!expense.isPaid && !isExpired(expense.dueDate)}
									positive={expense.isPaid}>
									<Table.Cell collapsing>
										# {index + 1}
									</Table.Cell>
									<Table.Cell>{format(new Date(expense.date), 'dd/MM/yyyy')}</Table.Cell>
									<Table.Cell>{expense.category?.name}</Table.Cell>
									<Table.Cell>{expense.notes}</Table.Cell>
									<Table.Cell style={{ textAlign: 'center' }}>
										<TotalAmount
											currency={expense.currency}
											total={total}
											color={'red'}
										/>
									</Table.Cell>
									<Table.Cell collapsing>
										<div
											style={{
												float: 'right',
												cursor: 'pointer',
											}}>
											<Icon
												color="blue"
												name="pencil alternate"
												onClick={() =>
													props.handleEdit(
														expense._id
													)
												}
											></Icon>
											<ConfirmModal
												id={expense._id}
												title={'Delete Expense'}
												message={`Are you sure that you want to delete the expense?`}
												triggerTitle={'Delete'}
												titleIcon="trash alternate"
												isDelete={true}
												isIcon
												onConfirm={props.handleDelete}
											/>
										</div>
									</Table.Cell>
								</Table.Row>
							);
					  }) : null}
			</Table.Body>
			<Table.Footer fullWidth>
				<Table.Row>
					<Table.HeaderCell />
					<Table.HeaderCell colSpan="5">
						<ExpenseModal categories={props.categories} onClose={props.handleClose}/>
						<ExpensePagination
							expenses={props.expenses}
							handlePageChange={props.handlePageChange}/>
					</Table.HeaderCell>
				</Table.Row>
			</Table.Footer>
		</Table>
	);
};
