import React, { Component, SyntheticEvent } from 'react';
import {
    Segment,
    Header,
    Button,
    Icon,
    Divider,
    Message,
    Item
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { RESOURCES } from '../../../actions/crud/resources';
import {
    getAllResources,
    getResource,
    setResourceId,
    deleteResource,
    setCurrentEntity
} from '../../../actions/crud/crud.actions';
import history from '../../../History';
import { InvoiceFilter } from './InvoiceFilter';
import { parseStartDate, parseEndDate } from '../../../utils/DateUtil';
import { InvoicePagination } from './InvoicePagination';
import { InvoiceItem } from './InvoiceItem';
import { Toast } from '../../../utils/ToastifyFactory';

type Props = {
    invoices: any;
    clients: any;
    getAllResources: Function,
    getResource: Function,
    setResourceId: Function,
    deleteResource: Function,
    setCurrentEntity: Function
};

type State = {
    activeIndex: number;
};

class InvoicePage extends Component<Props, State> {

    filters = {
		'client._id': '',
		startDate: null,
		endDate: null
	};
    state = { activeIndex: -1 };

    async componentDidMount() {
        await this.props.getAllResources(RESOURCES.invoices, { sort: { date: -1 }});
        await this.props.getAllResources(RESOURCES.clients);
    }

    handlePageChange = async (e: SyntheticEvent, data: any): Promise<void> => {
		await this.props.getAllResources(RESOURCES.invoices, {
			sort: { date: -1 },
			page: data.activePage
		});
    }

    handleCreate = () => {
        this.props.setCurrentEntity(RESOURCES.invoices, null);
        history.push(`${RESOURCES.invoices}/save`);
    }

    handleEdit = async (id: string) => {
        await this.props.getResource(RESOURCES.invoices, id, `${RESOURCES.invoices}/${id}/save`);
    }

    handleDelete = async (id: string) => {
        await this.props.deleteResource(RESOURCES.invoices, id);
        Toast.success('Invoice deleted successfully!');
    }

    handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;
        this.setState({ activeIndex: newIndex });
    }

    handleClientSelect = async (data: any) => {
        this.filters['client._id'] = data.id
        await this.props.getAllResources(RESOURCES.invoices, {
            sort: { date: -1 },
            filters: { 'client._id': data.id }
        })
	}

    handleRefresh = async () => {
        this.filters = {
            'client._id': '',
            startDate: null,
            endDate: null
        };
        await this.props.getAllResources(RESOURCES.invoices, {sort: { date: -1 }})
    }

    handleStartDateChange = async (date: Date) => {
		this.filters.startDate = date as any;
		const filters = this.buildFilters();
		console.log(filters);
		await this.props.getAllResources(RESOURCES.invoices, { filters, sort: { date: -1 } });
	}

	handleEndDateChange = async (date: Date) => {
		this.filters.endDate = date as any;
		const filters = this.buildFilters();
		console.log(filters);
		await this.props.getAllResources(RESOURCES.invoices, { filters, sort: { date: -1 } });
	}

    buildFilters = (): any => {
		const filters = this.filters as any;
		const finalFilter = {} as any;
		if (filters['client._id']) { finalFilter['client._id'] = filters['client._id'] };
		if (filters.startDate) {
			if (finalFilter.date) { finalFilter.date.$gte = parseStartDate(filters.startDate) }
			else { finalFilter.date = { $gte: parseStartDate(filters.startDate) } };
		};
		if (filters.endDate) {
			if (finalFilter.date) { finalFilter.date.$lte = parseEndDate(filters.endDate) }
			else { finalFilter.date = { $lte: parseEndDate(filters.endDate) } };
		};
		return finalFilter;
	}

    render() {
        const invoices = this.props.invoices;
        let render;
        let cardItems;
        const style = { backgroundColor: '#f5f5f5' };
        // const { activeIndex } = this.state;
        if (!isEmpty(invoices?.values)) {
            cardItems = invoices?.values.map((invoice: any, index: number) => {
                return (
                    <InvoiceItem
                        key={invoice._id}
                        invoice={invoice}
                        handleClick={this.handleClick}
                        handleEdit={this.handleEdit}
                        handleDelete={this.handleDelete}>
                    </InvoiceItem>
                )
            });
            render = (
                <Segment color='green' style={style}>
                    <Item.Group divided>
                        {cardItems}
                    </Item.Group>
                </Segment>
            );
        } else {
            render = (
                <Message info>
                    <Message.Header>There are no invoices to show!</Message.Header>
                    <p>You can add invoices with the button located on the top right</p>
                </Message>
            );
        }
        return (
            <div>
                <Segment clearing color='green' style={style}>
                    <Header as='h2' floated='left'>Invoices</Header>
                    <Button
                        onClick={() => this.handleCreate()}
                        icon
                        color='green'
                        labelPosition='left'
                        floated='right'>
                        <Icon name='plus square outline'/> Add Invoice
                    </Button>
                </Segment>
                <Divider />
                <InvoiceFilter
                    clients={this.props.clients?.values}
                    handleClientSelect={this.handleClientSelect}
                    handleStartDateChange={this.handleStartDateChange}
                    handleEndDateChange={this.handleEndDateChange}
                    handleRefresh={this.handleRefresh}>
                    <InvoicePagination
                        handlePageChange={this.handlePageChange}
                        invoices={invoices}
                    />
                </InvoiceFilter>              
                <Divider />
                {render}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        invoices: state.entities.invoice?.invoices,
        clients: state.entities.client.clients
    };
};

export default connect(mapStateToProps, {
    getAllResources,
    getResource,
    setResourceId,
    deleteResource,
    setCurrentEntity
})(InvoicePage);