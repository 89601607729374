import React, { Component } from 'react';
import { RESOURCES } from '../../../actions/crud/resources';
import { Button, Modal, Header, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
    getResource,
    getAllResources,
    postResource,
    putResource,
	setCurrentEntity,
	openModal,
	uploadImage
} from '../../../actions/crud/crud.actions';
import { ExpenseForm } from './ExpenseForm';

class ExpenseModal extends Component<any, any> {

	handleOpen = () => {
		this.props.setCurrentEntity(RESOURCES.expenses, null);
		this.props.openModal(RESOURCES.expenses, !this.props.isOpenModal);
	};

	handleClose = async (refresh: boolean) => {
		await this.props.onClose(refresh);
		this.props.setCurrentEntity(RESOURCES.expenses, null);
		this.props.openModal(RESOURCES.expenses, !this.props.isOpenModal);
	};

	onUploadImage = async (id: string, file: any) => {
        await uploadImage(id, RESOURCES.expenses, file);
    }

	render() {
		const style = { flex: 'auto' };
		const trigger = (
			<Button
				onClick={this.handleOpen}
				icon
				color={this.props.color || "blue"}
				labelPosition="left"
				floated="right"
			>
				<Icon name="dollar sign" /> Add Expense
			</Button>
		);
		const initialValues = this.props.currentExpense;
		return (
			<Modal
				dimmer='blurring'
				open={this.props.isOpenModal}
				trigger={trigger}
				size="large"
				onClose={() => this.handleClose(false)}
				closeIcon
			>
				<Header content="Save an Expense" icon="pencil alternate" />
				<Modal.Content image>
					<Modal.Description style={style}>
						<ExpenseForm
							initialValues={initialValues}
							onSubmit={this.props.postResource}
							onSubmitEdit={this.props.putResource}
							categories={this.props.categories}
							onClose={this.handleClose}
							loggedUser={this.props.loggedUser}
							onUploadImage={this.onUploadImage}
						/>
					</Modal.Description>
				</Modal.Content>
			</Modal>
		);
	}
}

const mapStateToProps = (state: any) => {
    return {
		currentExpense: state.entities.expense.currentExpense,
		isOpenModal: state.entities.expense?.isOpenModal,
		loggedUser: state.auth.loggedUser
    };
};

export default connect(mapStateToProps, {
    getAllResources,
    getResource,
    postResource,
    putResource,
	setCurrentEntity,
	openModal
})(ExpenseModal);
